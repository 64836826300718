
import React, { useState } from 'react';
import { Table, Button, Space, Tooltip, Select } from 'antd';
import { TVP_Final_Program} from '../graphql/types';
import { EditOutlined, DeleteOutlined, DownloadOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { SortOrder } from 'antd/es/table/interface';
import './RvpProgramTable.css';
import TvpFinalProgramsExport from './TvpFinalProgramsExport';
import config from '../config';

type TvpFinalProgramTableProps = {
  tvpFinalPrograms: TVP_Final_Program[];
  onCopyToDraft: (tvpFinalProgram: TVP_Final_Program) => void;
  onRetire: (tvpFinalProgram: TVP_Final_Program) => void;
  onRead: (tvpFinalProgram: TVP_Final_Program) => void;
};


const TvpFinalProgramTable: React.FC<TvpFinalProgramTableProps> = ({ tvpFinalPrograms, onCopyToDraft, onRetire, onRead }) => {

  //const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  // @ts-ignore
  const userRoles: string[] = JSON.parse( localStorage.getItem(config.rolesKey));

  const handleDownloadExcel = () => {
    TvpFinalProgramsExport(tvpFinalPrograms); // Call the exportToExcel function with your data and filename
  };

  const uniqueSmes = Array.from(new Set(tvpFinalPrograms.map((program) => program.SmeResponsible)));
  // Sort the uniqueSmes array
  uniqueSmes.sort((a, b) => {
    return a.localeCompare(b);
  });

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(value) => setSelectedKeys(value ? [value] : [])}
          //onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        >
         {uniqueSmes.map((SmeResponsible) => (
        <Select.Option key={SmeResponsible} value={SmeResponsible}>
          {SmeResponsible}
        </Select.Option>
      ))}
        </Select>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button type="button" onClick={() => clearFilters()}>
            Reset
          </button>
          <button type="button" onClick={() => confirm()}>
            Filter
          </button>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => <span>{filtered ? '🔍' : '🔎'}</span>,
    onFilter: (value: string | number | boolean, record: any) =>
      typeof value === 'string' && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setSearchedColumn(dataIndex);
      }
    },
    render: (text: string, record: any) => (
      searchedColumn === dataIndex ? (
        <span>{text}</span>
      ) : (
        text
      )
    ),
  });
  

  const columns = [
    {
      title: 'Staff Responsible',
      dataIndex: 'SmeResponsible',
      key: 'SmeResponsible',
      width: 200,
      sorter: (a: TVP_Final_Program, b: TVP_Final_Program) => a.SmeResponsible.localeCompare(b.SmeResponsible),
      sortDirections: ['ascend', 'descend'] as SortOrder[],
      defaultSortOrder: 'ascend' as SortOrder, // Set default sort order to 'ascend' for this column
      ...getColumnSearchProps('SmeResponsible'),
    },
    {
      title: 'Program Name',
      dataIndex: 'ProgramName',
      key: 'ProgramName',
      width: 190,
      sorter: (a: TVP_Final_Program, b: TVP_Final_Program) => a.ProgramName.localeCompare(b.ProgramName),
      sortDirections: ['ascend', 'descend'] as SortOrder[],
      
    },
    {
      title: 'Regulatory Problem',
      dataIndex: 'RegulatoryProblem',
      key: 'RegulatoryProblem',
      ellipsis: true,
      width: 180,
      sorter: (a: TVP_Final_Program, b: TVP_Final_Program) => a.RegulatoryProblem.localeCompare(b.RegulatoryProblem),
      sortDirections: ['ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Requirements To Verify',
      dataIndex: 'RequirementsToVerify',
      key: 'RequirementsToVerify',
      //ellipsis: true,
      width: 210,
      sorter: (a: TVP_Final_Program, b: TVP_Final_Program) => a.RequirementsToVerify.localeCompare(b.RequirementsToVerify),
      sortDirections: ['ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Reason',
      dataIndex: 'Reason',
      key: 'Reason',
      width: 180,
      sorter: (a: TVP_Final_Program, b: TVP_Final_Program) => a.Reason.localeCompare(b.Reason),
      sortDirections: ['ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Intent',
      dataIndex: 'Intent',
      key: 'Intent',
      width: 220,
      sorter: (a: TVP_Final_Program, b: TVP_Final_Program) => a.Intent.localeCompare(b.Intent),
      sortDirections: ['ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Number Of Verification',
      dataIndex: 'NumberOfVerification',
      key: 'NumberOfVerification',
      //ellipsis: true,
      width: 110,
      sorter: (a: TVP_Final_Program, b: TVP_Final_Program) => a.NumberOfVerification - b.NumberOfVerification,
      sortDirections: ['ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Time / workdays',
      dataIndex: 'NumberOfWorkday',
      key: 'NumberOfWorkday',
      render: (text:any) => (
        <span>
          {text} Days
        </span>
      ),
      width: 100,
      sorter: (a: TVP_Final_Program, b: TVP_Final_Program) => a.NumberOfWorkday - b.NumberOfWorkday,
      sortDirections: ['ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'FY Start Date',
      dataIndex: 'FiscalYearStartDate',
      key: 'FiscalYearStartDate',
      width: 160,
      sorter: (a: TVP_Final_Program, b: TVP_Final_Program) => a.FiscalYearStartDate.localeCompare(b.FiscalYearStartDate),
      sortDirections: ['ascend', 'descend'] as SortOrder[],
    },
    {
      title: (
        <Space >
          <Button type="ghost" shape="circle" icon={<DownloadOutlined />} onClick={handleDownloadExcel} />
        </Space>
      ),
      key: 'action',
      render: (_: any, record: TVP_Final_Program) => (
        <Space direction="vertical">
          <Tooltip title="Read">
            <Button
              style={{ backgroundColor: '#ffbf00' }}
              type="primary"
              size="small"
              icon={<EditOutlined />}
              onClick={() => onRead(record)}
              // disabled={!userRoles.includes('Writer')}
            />
          </Tooltip>
          <Tooltip title="Make a copy to draft">
              <Button
                type="primary"
                size="small"
                icon={<PlusSquareOutlined />}
                onClick={() => onCopyToDraft(record)}
                disabled={!userRoles.includes('Writer')}
              >
              </Button>
            </Tooltip>
            <Tooltip title="Click to retire">
              <Button
                danger
                type="primary"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => onRetire(record)}
                disabled={!userRoles.includes('Publisher')}
              >
              </Button>
            </Tooltip>
        </Space>
      ),
    }

  ]

  return <Table
    //scroll={{ x: "max-content" }}
    scroll={{ x: "scroll" }}
    //scroll={{ x: 2000 }}
    columns={columns}
    dataSource={tvpFinalPrograms}
    pagination={{ pageSize: 100 }}
    rowKey="Id"
    sticky={true}
    />;
};

export default TvpFinalProgramTable;
