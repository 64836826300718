// import { Image, Space, Typography } from "antd";
// import { Link } from 'react-router-dom';
// import { WelcomeName } from "../WelcomeName";
// import { SignInButton } from "../SignInButton";
// import { SignOutButton } from "../SignOutButton";
// import { useIsAuthenticated } from "@azure/msal-react";

// function AppHeader() {
//   const isAuthenticated = useIsAuthenticated();

//   return (
//     <div className="AppHeader">
//        <Link to="/">
//             <Image preview={false} width={110} src={process.env.PUBLIC_URL + '/AER_logo_RGB.png'} />
//       </Link>
//       <Space>
//         <a
//           className="LinkFont"
//           target="_blank"
//           rel="noreferrer"
//           href="https://www.aer.ca/regulating-development/compliance/inspections-and-audits"
//         >
//           About
//         </a>
//       </Space>
//       <Typography.Title level={2}>
//         AER Verification Programs Portal
//       </Typography.Title>
//       {isAuthenticated ? <WelcomeName /> : null}
//       {isAuthenticated ? <SignOutButton /> : <SignInButton />}
//     </div>
//   );
// }

// export default AppHeader;


import { Image, Typography, Space } from "antd";
import { Link } from 'react-router-dom';
import { WelcomeName } from "../WelcomeName";
import { SignInButton } from "../SignInButton";
import { SignOutButton } from "../SignOutButton";
import { useIsAuthenticated } from "@azure/msal-react";

function AppHeader() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <div className="AppHeader" style={{ display: "flex", alignItems: "center" }}>
      <Link to="/" style={{ marginRight: "auto" }}>
        <Image preview={false} width={110} src={process.env.PUBLIC_URL + '/AER_logo_RGB.png'} />
      </Link>
      <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Typography.Title level={2} style={{ margin: 10 }}>
          AER Verification Programs Repository
        </Typography.Title>
        <div style={{ marginTop: 10 }}>
          <Space>
            <a
              className="LinkFont"
              target="_blank"
              rel="noreferrer"
              href="https://www.aer.ca/regulating-development/compliance/inspections-and-audits"
            >
              About
            </a>
          </Space>
        </div>
      </div>
      <div>
        {isAuthenticated ? (
          <Space>
            <WelcomeName />
            <SignOutButton />
          </Space>
        ) : (
          <SignInButton />
        )}
      </div>
    </div>
  );
}

export default AppHeader;
