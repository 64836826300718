
import moment from 'moment';
import * as XLSX from 'xlsx';
import { TVP_Final_Program} from '../graphql/types';
const fileSaver = require('file-saver');

const TvpFinalProgramsExport = (tvpFinalPrograms: TVP_Final_Program[]) => {
  const exportData = tvpFinalPrograms.map((program) => {
      return {
        'Staff responsible': program.SmeResponsible,
        'Program name': program.ProgramName,       
        'Regulatory problem (issue) to target': program.RegulatoryProblem,
        'Requirements to verify': program.RequirementsToVerify,
        'Reason': program.Reason,
        'Intent': program.Intent,
        'Risk': program.Risk,
        'Consequence': program.Consequence,
        'information gap': program.InformationGap,
        'Hazard': program.HazardType,
        'Resource': program.ResourceType,
        'Lifecycle phase': program.LifeCycleStage,
        'Discipline': program.Discipline,
        'Target identification': program.TargetIdentification,
        'Target detail': program.TargetDetail,
        'Preferred start date': program.PreferredStartDate ? moment(Number(program.PreferredStartDate)).format('YYYY-MM-DD') : '',
        'Preferred end date': program.PreferredEndDate ? moment(Number(program.PreferredEndDate)).format('YYYY-MM-DD') : '',
        'Staff': program.Staff,
        'Number of verification': program.NumberOfVerification,
        'Number of workday': program.NumberOfWorkday,
        'System': program.System,
        'FY Start Date': program.FiscalYearStartDate,
        'Created by': program.Creator,
        'Create Date': program.CreationInstant ? moment(Number(program.CreationInstant)).format('YYYY-MM-DD') : '',
        'Modified by': program.Modifier,
        'Modify Date': program.ModificationInstant ? moment(Number(program.ModificationInstant)).format('YYYY-MM-DD') : '',

      };
  });

  exportData.sort((a, b) => {
    const staffA = a['Staff responsible'].toUpperCase();
    const staffB = b['Staff responsible'].toUpperCase();
    if (staffA < staffB) {
      return -1;
    }
    if (staffA > staffB) {
      return 1;
    }
    return 0;
  });

  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'TVP Final Program');

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  fileSaver.saveAs(excelData, 'tvp_final_programs.xlsx');
};

export default TvpFinalProgramsExport;
