import { Menu} from "antd";
import { CheckOutlined, PlusOutlined, UnorderedListOutlined} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

function SideMenu() {
    const navigate = useNavigate()
    return <div className="SideMenu">
        <Menu className="SideMenuVertical" 
        mode="inline"
        //defaultOpenKeys={"/RVPDraft"}
        onClick={(item) =>{
            navigate(item.key)
        }}
            items={[
                {
                    label: "RVP",
                    icon:<CheckOutlined />,
                    key: "RVP",
                    children:[
                        {label:"Final", key: "/rvpFinal"},
                        {label:"Draft", key: "/rvpDraft"},

                    ]
                },
                {
                    label: "TVP",
                    icon:<PlusOutlined />,
                    key: "/Target",
                    children:[
                        {label:"Final", key: "/targetFinal"},
                        {label:"Draft", key: "/targetDraft"},
                    ]
                },
                {
                    label: "RVP Population",
                    icon:<UnorderedListOutlined />,
                    key: "/SampleData",
                    children:[
                        {label:"Well", key: "/sampleRVP/wellPopulation"},
                        {label:"Drilling", key: "/SampleRVP/drillPopulation"},
                    ]
                },
            ]
            }></Menu>
    </div>
}

export default SideMenu;