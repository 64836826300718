import { useState } from 'react';
import { Typography, Button, notification, Modal, Space, Input } from 'antd';
import { useQuery } from '@apollo/client';

import {RVP_Final_Program, RVP_Draft_Program, RVP_Draft_Stratum, RvpDraftStratumInput } from '../../graphql/types';
import { GET_RVP_DRAFT_PROGRAMS_STRATA } from '../../graphql/queries';
import { GET_RVP_FINAL_PROGRAMS } from '../../graphql/queries';

import ExcelLoader from '../../components/ExcelLoader';
import config from '../../config';
import {
  useCreateRvpDraftProgramMutation,
  useUpdateRvpDraftProgramMutation,
  useDeleteRvpDraftProgramMutation,
  useCreateRvpDraftStratumMutation,
  useUpdateRvpDraftStratumMutation,
  useDeleteRvpDraftStratumMutation,
  usePublishRvpDraftProgramMutation,
} from '../../hooks/useMutations';

import RvpDraftProgramTable from '../../components/RvpDraftProgramTable';

import RvpDraftProgramFormModal from '../../components/RvpDraftProgramFormModal';
import RvpDraftStratumFormModal from '../../components/RvpDraftStratumFormModal';

function RvpDraft() {
  // @ts-ignore
  const userRoles: string[] = JSON.parse( localStorage.getItem(config.rolesKey));
   // @ts-ignore
   const idTokenClaims = JSON.parse(localStorage.getItem(config.tokenClaimsKey));
   const userEmail = idTokenClaims.preferred_username;

  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [deletingRvpDraftProgram, setDeletingRvpDraftProgram] = useState<RVP_Draft_Program | undefined>(undefined);
  const [deleteStratumConfirmationVisible, setDeleteStratumConfirmationVisible] = useState(false);
  const [deletingRvpDraftStratum, setDeletingRvpDraftStratum] = useState<RVP_Draft_Stratum | undefined>(undefined);
  const [publishConfirmationVisible, setPublishConfirmationVisible] = useState(false);
  const [publishingRvpDraftProgram, setPublishingRvpDraftProgram] = useState<RVP_Draft_Program | undefined>(undefined);
  const [inputApproverValue, setInputApproverValue] = useState('');
  const [inputSmeResponsibleValue, setInputSmeResponsibleValue] = useState('');
  const [inputApprovalDateValue, setInputApprovalDateValue] = useState('');

  const [visibleProgramForm, setVisibleProgramForm] = useState(false);
  const [editingRvpDraftProgram, setEditingRvpDraftProgram] = useState<RVP_Draft_Program | undefined>(undefined);
  const [visibleStratumForm, setVisibleStratumForm] = useState(false);
  const [editingRvpDraftStratum, setEditingRvpDraftStratum] = useState<RvpDraftStratumInput>();


  const { loading, error, data } = useQuery(GET_RVP_DRAFT_PROGRAMS_STRATA);

  const [createRvpDraftProgram] = useCreateRvpDraftProgramMutation();
  const [updateRvpDraftProgram] = useUpdateRvpDraftProgramMutation();
  const [deleteRvpDraftProgram] = useDeleteRvpDraftProgramMutation();
  const [publishRvpDraftProgram] = usePublishRvpDraftProgramMutation();

  const [createRvpDraftStratum] = useCreateRvpDraftStratumMutation();
  const [updateRvpDraftStratum] = useUpdateRvpDraftStratumMutation();
  const [deleteRvpDraftStratum] = useDeleteRvpDraftStratumMutation();

  const showModal = (editingRvpDraftProgram?: RVP_Draft_Program) => {
    setEditingRvpDraftProgram(editingRvpDraftProgram);
    setVisibleProgramForm(true);
  };

  const showModalStratumEdit = (editingRvpDraftStratum: RVP_Draft_Stratum) => {
    setEditingRvpDraftStratum(editingRvpDraftStratum);
    setVisibleStratumForm(true);
  };

  const showModalStratumCreate = (rvpDraftProgram: RVP_Draft_Program) => {
    const newStratum: RvpDraftStratumInput = {
      rvp_draft_id_sk: rvpDraftProgram.idSk,
      idSk: '',
      stratum_id: rvpDraftProgram.discipline,
      location: '',
      operator: '',
      activity: '',
      stratum_population: 0,
      confidence_level: 95,
      sample_proportion: 95,
      margin_of_error: 5,
      extraction_method: 'Systematic',
      extraction_frequency: '',
      comment: '',
      row_created_by: userEmail,
      row_modified_by: '',
      annotation: '',
    };

    setEditingRvpDraftStratum(newStratum);
    setVisibleStratumForm(true);
  };

  const handleCreate = async (values: RVP_Draft_Program) => {
    try {
      const { data } = await createRvpDraftProgram({ variables: { newRvpDraftProgram: values } });
      if (data?.createRvpDraftProgram && data?.createRvpDraftProgram.idSk) {
        setVisibleProgramForm(false);
        notification.success({ message: 'Rvp draft program added successfuly!' });
      }
      else {
        notification.error({ message: 'Adding rvp draft program failed!' });
      }
    } catch (error) {
      notification.error({ message: 'Adding rvp draft program failed!' });
    }
  };

  const handleUpdate = async (values: RVP_Draft_Program) => {
    try {
      const { data } = await updateRvpDraftProgram({
        variables: { newRvpDraftProgram: values },
      });
      if (data?.updateRvpDraftProgram) {
        setVisibleProgramForm(false);
        notification.success({ message: 'Update successful!' });
      }
      else {
        notification.error({ message: 'Update failed!' });
      }
    } catch (error) {
      notification.error({ message: 'Update failed!' });
    }
  };

  const handleUpdateOrCreate = async (values: RVP_Draft_Program, createMode: boolean) => {
    if (createMode) handleCreate(values);
    else handleUpdate(values);
  };

  const handleCreateStratum = async (values: RVP_Draft_Stratum) => {
    try {
      const { data } = await createRvpDraftStratum({ variables: { newRvpDraftStratum: values } });
      if (data?.createRvpDraftStratum) {
        setVisibleStratumForm(false);
        notification.success({ message: 'Rvp draft stratum added successfuly!' });
      }
      else {
        notification.error({ message: 'Adding rvp draft stratum failed!' });
      }

    } catch (error) {
      notification.error({ message: 'Adding rvp draft stratum failed!' });
    }

  };

  const handleUpdateStratum = async (values: RVP_Draft_Stratum) => {
    try {
      const { data } = await updateRvpDraftStratum({
        variables: { newRvpDraftStratum: values },
      });
      if (data?.updateRvpDraftStratum) {
        setVisibleStratumForm(false);
        notification.success({ message: 'Update successful!' });
      }
      else {
        notification.error({ message: 'Update failed!' });
      }
    } catch (error) {
      notification.error({ message: 'Update failed!' });
    }
  };

  const handleUpdateOrCreateStratum = async (stratum: RVP_Draft_Stratum) => {
    stratum.idSk ? handleUpdateStratum(stratum) : handleCreateStratum(stratum);
  };

  const handleDelete = async (currentRvpDraftProgram: RVP_Draft_Program) => {
    setDeletingRvpDraftProgram(currentRvpDraftProgram);
    setDeleteConfirmationVisible(true);
  };

  const { loading: finalLoading, error: finalError, data: finalData, refetch: refetchRVPFinal } = useQuery(GET_RVP_FINAL_PROGRAMS);
  refetchRVPFinal();
  const rvpFinalPrograms = finalData?.rvpFinalPrograms ?? [];
  const handlePublish = async (currentRvpDraftProgram: RVP_Draft_Program) => {
    setPublishingRvpDraftProgram(currentRvpDraftProgram);
    
    // Check if the record to be published already exists in rvpFinalPrograms
    const existingRecord = rvpFinalPrograms.find((program: RVP_Final_Program) =>
      program.program === currentRvpDraftProgram.program &&
      program.fiscal_year_start_date === currentRvpDraftProgram.fiscal_year_start_date
    );

    if (existingRecord) {
      // Display a warning modal
      Modal.confirm({
        title: 'Duplicate Record',
        content: 'A record with the same "Program Name" and "FY Start Date" already exists in RVP Final. Do you want to continue publishing?',
        okText: 'Publish',
        cancelText: 'Cancel',
        onOk: async () => {
          // Proceed with publishing
          setPublishConfirmationVisible(true);
        },
        onCancel: () => {
          // Handle cancel action here
          // For example, close the modal
          setPublishConfirmationVisible(false);
        }
      });
    } else {
      // No duplicates found, proceed with publishing directly
      setPublishConfirmationVisible(true);
    }  
  };

  const handleDeleteStratum = async (currentRvpDraftStratum: RVP_Draft_Stratum) => {
    setDeletingRvpDraftStratum(currentRvpDraftStratum);
    setDeleteStratumConfirmationVisible(true);
  };

  const confirmDelete = async () => {
    try {
      const { data } = await deleteRvpDraftProgram({
        variables: { idSk: deletingRvpDraftProgram?.idSk || "" },
      });
      if (data?.deleteRvpDraftProgram) {
        setVisibleProgramForm(false);
        notification.success({ message: "Deletion successful!" });
      }
      else {
        notification.error({ message: "The record was not deleted!" });
      }

    } catch (error) {
      notification.error({ message: "The record was not deleted!" });
    }
    setDeleteConfirmationVisible(false);
  };

  const confirmStratumDelete = async () => {
    try {
      const { data } = await deleteRvpDraftStratum({
        variables: { idSk: deletingRvpDraftStratum?.idSk || "" },
      });
      if (data?.deleteRvpDraftStratum) {
        setVisibleStratumForm(false);
        notification.success({ message: "Deletion successful!" });
      }
      else {
        notification.error({ message: "The record was not deleted!" });
      }
    } catch (error) {
      notification.error({ message: "The record was not deleted!" });
    }
    setDeleteStratumConfirmationVisible(false);
  };

  const confirmPublish = async () => {
    try {
      if (inputApproverValue && inputApprovalDateValue && inputSmeResponsibleValue) {
        const { data } = await publishRvpDraftProgram({
          variables: {
            idSk: publishingRvpDraftProgram?.idSk || "",
            approvedBy: inputApproverValue, approvalDate: inputApprovalDateValue,
            rowCreatedBy: userEmail,
            smeResponsible: inputSmeResponsibleValue
          },
        });
        if (data?.publishRvpDraftProgram) {
          notification.success({ message: "Publishing successful!" });
          refetchRVPFinal();
        }
        else {
          notification.error({ message: "The program was not published!" });
        }
      }
      else {
        notification.error({ message: "The required information must be filled!" });
      }
    } catch (error) {
      notification.error({ message: "The program was not published!" });
    }
    setPublishConfirmationVisible(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const rvpDraftPrograms = data?.rvpDraftProgramsStrata ?? [];
  // Calculate today's date
  const today = new Date().toISOString().split('T')[0];

  return (
    <div>
      <Typography.Title level={3}>
        List of Random Verification Programs (Draft)
      </Typography.Title>
      <div className="site-layout-content">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Button type="primary" onClick={() => showModal()} style={{ marginBottom: '6px' }}
          disabled={!userRoles.includes('Writer')}>
            Create a New Program
          </Button>
          <h1 style={{ marginLeft: 'auto' }}>Load from Excel:</h1><span>&nbsp;</span><ExcelLoader />
        </div>

        <RvpDraftProgramTable
          rvpDraftPrograms={rvpDraftPrograms}
          onEdit={showModal}
          onDelete={handleDelete}
          onEditStratum={showModalStratumEdit}
          onDeleteStratum={handleDeleteStratum}
          onCreateStratum={showModalStratumCreate}
          onPublish={handlePublish}
        />
        {visibleProgramForm && (
          <RvpDraftProgramFormModal
            visible={visibleProgramForm}
            initialValues={editingRvpDraftProgram as RVP_Draft_Program}
            onSubmit={handleUpdateOrCreate}
            onCancel={() => setVisibleProgramForm(false)}
          />
        )}
        {visibleStratumForm && (
          <RvpDraftStratumFormModal
            visible={visibleStratumForm}
            initialValues={editingRvpDraftStratum as RVP_Draft_Stratum}
            onSubmit={handleUpdateOrCreateStratum}
            onCancel={() => setVisibleStratumForm(false)}
          />
        )}
        <Modal
          open={deleteConfirmationVisible}
          title="Confirm Deletion"
          okText="Delete"
          cancelText="Cancel"
          onCancel={() => setDeleteConfirmationVisible(false)}
          onOk={confirmDelete}
          okButtonProps={{ danger: true }}
          maskClosable={false}
        >
          Are you sure you want to delete this program?
        </Modal>
        <Modal
          open={deleteStratumConfirmationVisible}
          title="Confirm Deletion"
          okText="Delete"
          cancelText="Cancel"
          onCancel={() => setDeleteStratumConfirmationVisible(false)}
          onOk={confirmStratumDelete}
          okButtonProps={{ danger: true }}
          maskClosable={false}
        >
          Are you sure you want to delete this stratum?
        </Modal>
        <Modal
          open={publishConfirmationVisible}
          title="To publish, please enter the following information."
          okText="Publish"
          cancelText="Cancel"
          onCancel={() => setPublishConfirmationVisible(false)}
          onOk={confirmPublish}
          okButtonProps={{ danger: true }}
          maskClosable={false}
        >
          <div style={{ marginBottom: '6px', paddingLeft: '16px' }}>
            <Space>
              <Typography.Text strong>SME Responsible:</Typography.Text>
              {''}
              <Input value={inputSmeResponsibleValue} style={{width: 200 }} onChange={(e) => setInputSmeResponsibleValue(e.target.value)} />
            </Space>
          </div>
          <div style={{ marginBottom: '6px', paddingLeft: '16px' }}>
            <Space>
              <Typography.Text strong>Approved By:</Typography.Text>
              {' '}
              <Input value={inputApproverValue} style={{width: 200 }} onChange={(e) => setInputApproverValue(e.target.value)} />
            </Space>
          </div>
          <div style={{ paddingLeft: '16px' }}>
            <Space>
              <Typography.Text strong>Approval Date:</Typography.Text>
              {'             '}
              <Input
                type="date"
                value={inputApprovalDateValue}
                style={{width: 200 }}
                onChange={(e) => setInputApprovalDateValue(e.target.value)}
                max={today}
              />
            </Space>
          </div>
        </Modal>
      </div>
    </div>
  );

}
export default RvpDraft