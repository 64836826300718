// useMutations.ts
import { useMutation, gql } from '@apollo/client';
import {TVP_Draft_Program, RVP_Draft_Program, RvpDraftProgramInput, RVP_Draft_Stratum, RvpDraftStratumInput, TvpDraftProgramInput,
  TVP_Final_Program, RVP_Final_Program, RvpFinalProgramInput, RVP_Final_Stratum, RvpFinalStratumInput,User, UserInput, R_Program, RProgramInput, RVP_Final_Program_Strata, RVP_Draft_Program_Strata } from '../graphql/types';
import { RvpDraftProgramFragment, RvpDraftStratumFragment, UserFragment, TvpDraftProgramFragment, TvpFinalProgramFragment,
  RvpFinalProgramFragment, RvpFinalStratumFragment,RProgramFragment, RvpFinalProgramStrataFragment, RvpDraftProgramStrataFragment } from '../graphql/fragments';
import { GET_TVP_DRAFT_PROGRAMS, GET_L_PROGRAM,  GET_RVP_DRAFT_PROGRAMS_STRATA, 
  GET_RVP_FINAL_PROGRAMS_STRATA, GET_USERS, GET_TVP_FINAL_PROGRAMS } from '../graphql/queries';

const CREATE_USER_MUTATION = gql`
  mutation CreateUser($newUser: UserInput) {
    createUser(newUser: $newUser) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($id: ID!, $newUser: UserInput) {
    updateUser(id: $id, newUser: $newUser) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($id: ID) {
    deleteUser(id: $id)
  }
`;

export function useCreateUserMutation() {
  return useMutation<{ createUser: User }, { newUser: UserInput }>(
    CREATE_USER_MUTATION,
    {
      refetchQueries: [{ query: GET_USERS }, 'getUsers'],
    }
  );
}

export function useUpdateUserMutation() {
  return useMutation<{ updateUser: User }, { id: string; newUser: UserInput }>(
    UPDATE_USER_MUTATION,
    {
      refetchQueries: [{ query: GET_USERS }, 'getUsers'],
    }
  );
}

export function useDeleteUserMutation() {
  return useMutation<{ deleteUser: boolean }, { id: string }>(
    DELETE_USER_MUTATION,
    {
      refetchQueries: [{ query: GET_USERS }, 'getUsers'],
    }
  );
}


//////////////////
const CREATE_Rvp_Draft_Program_MUTATION = gql`
  mutation CreateRvpDraftProgram($newRvpDraftProgram: RvpDraftProgramInput) {
  createRvpDraftProgram(newRvpDraftProgram: $newRvpDraftProgram) {
    ...RvpDraftProgramFragment
  }
  }
  ${RvpDraftProgramFragment}
`;

const UPDATE_Rvp_Draft_Program_MUTATION = gql`
  mutation UpdateRvpDraftProgram($newRvpDraftProgram: RvpDraftProgramInput) {
  updateRvpDraftProgram(newRvpDraftProgram: $newRvpDraftProgram) {
    ...RvpDraftProgramFragment
  }
  }
  ${RvpDraftProgramFragment}
`;

const DELETE_Rvp_Draft_Program_MUTATION = gql`
 mutation DeleteRvpDraftProgram($idSk: String) {
  deleteRvpDraftProgram(idSk: $idSk)
}
`;

export function useCreateRvpDraftProgramMutation() {
  return useMutation<{ createRvpDraftProgram: RVP_Draft_Program }, { newRvpDraftProgram: RvpDraftProgramInput}>(
    CREATE_Rvp_Draft_Program_MUTATION,
    {
      refetchQueries: [{ query: GET_RVP_DRAFT_PROGRAMS_STRATA }, 'getRvpDraftProgramsStrata'],
    }
  );
}

export function useUpdateRvpDraftProgramMutation() {
  return useMutation<{ updateRvpDraftProgram: RVP_Draft_Program }, { newRvpDraftProgram: RvpDraftProgramInput}>(
    UPDATE_Rvp_Draft_Program_MUTATION,
    {
      refetchQueries: [{ query: GET_RVP_DRAFT_PROGRAMS_STRATA }, 'getRvpDraftProgramsStrata'],
    }
  );
}

export function useDeleteRvpDraftProgramMutation() {
  return useMutation<{ deleteRvpDraftProgram: boolean }, { idSk: string }>(
    DELETE_Rvp_Draft_Program_MUTATION,
    {
      refetchQueries: [{ query: GET_RVP_DRAFT_PROGRAMS_STRATA }, 'getRvpDraftProgramsStrata'],
    }
  );
}

//////////////////
const CREATE_Rvp_Draft_Stratum_MUTATION = gql`
  mutation CreateRvpDraftStratum($newRvpDraftStratum: RvpDraftStratumInput) {
  createRvpDraftStratum(newRvpDraftStratum: $newRvpDraftStratum) {
    ...RvpDraftStratumFragment
  }
  }
  ${RvpDraftStratumFragment}
`;

const UPDATE_Rvp_Draft_Stratum_MUTATION = gql`
  mutation UpdateRvpDraftStratum($newRvpDraftStratum: RvpDraftStratumInput) {
  updateRvpDraftStratum(newRvpDraftStratum: $newRvpDraftStratum) {
    ...RvpDraftStratumFragment
  }
  }
  ${RvpDraftStratumFragment}
`;

const DELETE_Rvp_Draft_Stratum_MUTATION = gql`
 mutation DeleteRvpDraftStratum($idSk: String) {
  deleteRvpDraftStratum(idSk: $idSk)
}
`;

export function useCreateRvpDraftStratumMutation() {
  return useMutation<{ createRvpDraftStratum: RVP_Draft_Stratum }, { newRvpDraftStratum: RvpDraftStratumInput}>(
    CREATE_Rvp_Draft_Stratum_MUTATION,
    {
      refetchQueries: [{ query: GET_RVP_DRAFT_PROGRAMS_STRATA }, 'getRvpDraftProgramsStrata'],
    }
  );
}

export function useUpdateRvpDraftStratumMutation() {
  return useMutation<{ updateRvpDraftStratum: RVP_Draft_Stratum }, { newRvpDraftStratum: RvpDraftStratumInput}>(
    UPDATE_Rvp_Draft_Stratum_MUTATION,
    {
      refetchQueries: [{ query: GET_RVP_DRAFT_PROGRAMS_STRATA }, 'getRvpDraftProgramsStrata'],
    }
  );
}

export function useDeleteRvpDraftStratumMutation() {
  return useMutation<{ deleteRvpDraftStratum: boolean }, { idSk: string }>(
    DELETE_Rvp_Draft_Stratum_MUTATION,
    {
      refetchQueries: [{ query: GET_RVP_DRAFT_PROGRAMS_STRATA }, 'getRvpDraftProgramsStrata'],
    }
  );
}

/////////////////////////
const CREATE_R_Program_MUTATION = gql`
  mutation CreateRProgram($newRProgram: RProgramInput) {
    createRProgram(newRProgram: $newRProgram) {
      ...RProgramFragment
    }
  }
  ${RProgramFragment}
`;

const UPDATE_R_Program_MUTATION = gql`
  mutation UpdateRProgram($newRProgram: RProgramInput) {
    updateRProgram(newRProgram: $newRProgram) {
      ...RProgramFragment
    }
  }
  ${RProgramFragment}
`;

export function useCreateRProgramMutation() {
  return useMutation<{ createRProgram: R_Program }, { newRProgram: RProgramInput }>(
    CREATE_R_Program_MUTATION,
    {
      refetchQueries: [{ query: GET_L_PROGRAM }, 'getLProgram'],
    }
  );
}

export function useUpdateRProgramMutation() {
  return useMutation<{ updateRProgram: R_Program }, { newRProgram: RProgramInput }>(
    UPDATE_R_Program_MUTATION,
    {
      refetchQueries: [{ query: GET_L_PROGRAM }, 'getLProgram'],
    }
  );
}

////////////////////////
/// rvp final tables ///
///////////////////////
const CREATE_Rvp_Final_Program_MUTATION = gql`
  mutation CreateRvpFinalProgram($newRvpFinalProgram: RvpFinalProgramInput) {
  createRvpFinalProgram(newRvpFinalProgram: $newRvpFinalProgram) {
    ...RvpFinalProgramFragment
  }
  }
  ${RvpFinalProgramFragment}
`;

export function useCreateRvpFinalProgramMutation() {
  return useMutation<{ createRvpFinalProgram: RVP_Final_Program }, { newRvpFinalProgram: RvpFinalProgramInput}>(
    CREATE_Rvp_Final_Program_MUTATION,
    {
      refetchQueries: [{ query: GET_RVP_FINAL_PROGRAMS_STRATA }, 'getRvpFinalProgramsStrata'],
    }
  );
}

//////////////////
const CREATE_Rvp_Final_Stratum_MUTATION = gql`
  mutation CreateRvpFinalStratum($newRvpFinalStratum: RvpFinalStratumInput) {
  createRvpFinalStratum(newRvpFinalStratum: $newRvpFinalStratum) {
    ...RvpFinalStratumFragment
  }
  }
  ${RvpFinalStratumFragment}
`;


export function useCreateRvpFinalStratumMutation() {
  return useMutation<{ createRvpFinalStratum: RVP_Final_Stratum }, { newRvpFinalStratum: RvpFinalStratumInput}>(
    CREATE_Rvp_Final_Stratum_MUTATION,
    {
      refetchQueries: [{ query: GET_RVP_FINAL_PROGRAMS_STRATA }, 'getRvpFinalProgramsStrata'],
    }
  );
}

///////////////////////
const PUBLISH_Rvp_Draft_Program_MUTATION = gql`
  mutation PublishRvpDraftProgram($idSk: String, $approvedBy: String, $approvalDate: String, $rowCreatedBy: String, $smeResponsible: String) {
  publishRvpDraftProgram(idSk: $idSk, approvedBy: $approvedBy, approvalDate: $approvalDate, rowCreatedBy: $rowCreatedBy, smeResponsible: $smeResponsible) {
    ...RvpFinalProgramStrataFragment
  }
  }
  ${RvpFinalProgramStrataFragment}
`;


export function usePublishRvpDraftProgramMutation() {
  return useMutation<{ publishRvpDraftProgram: RVP_Final_Program_Strata }, { idSk: String; approvedBy: String; approvalDate: String; rowCreatedBy: String; smeResponsible: String}>(
    PUBLISH_Rvp_Draft_Program_MUTATION,
    {
      //refetchQueries: [{ query: GET_RVP_DRAFT_PROGRAMS_STRATA }, 'getRvpDraftProgramsStrata'],
      refetchQueries: [
        { query: GET_RVP_DRAFT_PROGRAMS_STRATA, variables: { /* optional variables for draft program query */ } },
        { query: GET_RVP_FINAL_PROGRAMS_STRATA, variables: { /* optional variables for final program query */ } },
      ],
    }
  );
}

///////////////////////
const COPY_Rvp_Final_To_Draft_Program_MUTATION = gql`
  mutation copyRvpFinalToDraftProgram($idSk: String, $fiscalYearStartDate: String,  $rowCreatedBy: String) {
    copyRvpFinalToDraftProgram(idSk: $idSk, fiscalYearStartDate: $fiscalYearStartDate, rowCreatedBy: $rowCreatedBy) {
    ...RvpDraftProgramStrataFragment
  }
  }
  ${RvpDraftProgramStrataFragment}
`;

export function useCopyRvpFinalToDraftProgramMutation() {
  return useMutation<{ copyRvpFinalToDraftProgram: RVP_Draft_Program_Strata }, { idSk: String; fiscalYearStartDate: String; rowCreatedBy: String}>(
    COPY_Rvp_Final_To_Draft_Program_MUTATION,
    {
      refetchQueries: [{ query: GET_RVP_DRAFT_PROGRAMS_STRATA }, 'getRvpDraftProgramsStrata'],
    }
  );
}

///////////////////////
const RETIRE_Rvp_Final_Program_MUTATION = gql`
  mutation RetireRvpFinalProgram($idSk: String, $comment: String, $rowModifiedBy: String) {
  retireRvpFinalProgram(idSk: $idSk, comment: $comment, rowModifiedBy: $rowModifiedBy) {
    ...RvpFinalProgramFragment
  }
  }
  ${RvpFinalProgramFragment}
`;


export function useRetireRvpFinalProgramMutation() {
  return useMutation<{ retireRvpFinalProgram: RVP_Final_Program_Strata }, { idSk: String; comment: String; rowModifiedBy: String}>(
    RETIRE_Rvp_Final_Program_MUTATION,
    {
      refetchQueries: [{ query: GET_RVP_FINAL_PROGRAMS_STRATA }, 'getRvpFinalProgramsStrata'],
    }
  );
}

///////// TVP Draft /////////
const CREATE_Tvp_Draft_Program_MUTATION = gql`
  mutation CreateTvpDraftProgram($newTvpDraftProgram: TvpDraftProgramInput) {
  createTvpDraftProgram(newTvpDraftProgram: $newTvpDraftProgram) {
    ...TvpDraftProgramFragment
  }
  }
  ${TvpDraftProgramFragment}
`;

const UPDATE_Tvp_Draft_Program_MUTATION = gql`
  mutation UpdateTvpDraftProgram($newTvpDraftProgram: TvpDraftProgramInput) {
  updateTvpDraftProgram(newTvpDraftProgram: $newTvpDraftProgram) {
    ...TvpDraftProgramFragment
  }
  }
  ${TvpDraftProgramFragment}
`;

const DELETE_Tvp_Draft_Program_MUTATION = gql`
 mutation DeleteTvpDraftProgram($Id: String) {
  deleteTvpDraftProgram(Id: $Id)
}
`;

export function useCreateTvpDraftProgramMutation() {
  return useMutation<{ createTvpDraftProgram: TVP_Draft_Program }, { newTvpDraftProgram: TvpDraftProgramInput}>(
    CREATE_Tvp_Draft_Program_MUTATION,
    {
      refetchQueries: [{ query: GET_TVP_DRAFT_PROGRAMS }, 'getTvpDraftPrograms'],
    }
  );
}

export function useUpdateTvpDraftProgramMutation() {
  return useMutation<{ updateTvpDraftProgram: TVP_Draft_Program }, { newTvpDraftProgram: TvpDraftProgramInput}>(
    UPDATE_Tvp_Draft_Program_MUTATION,
    {
      refetchQueries: [{ query: GET_TVP_DRAFT_PROGRAMS }, 'getTvpDraftPrograms'],
    }
  );
}

export function useDeleteTvpDraftProgramMutation() {
  return useMutation<{ deleteTvpDraftProgram: boolean }, { Id: string }>(
    DELETE_Tvp_Draft_Program_MUTATION,
    {
      refetchQueries: [{ query: GET_TVP_DRAFT_PROGRAMS}, 'getTvpDraftPrograms'],
    }
  );
}


///////////////////////
const PUBLISH_Tvp_Draft_Program_MUTATION = gql`
  mutation PublishTvpDraftProgram($Id: String, $ApprovedBy: String, $ApprovalDate: String, $Creator: String) {
  publishTvpDraftProgram(Id: $Id, ApprovedBy: $ApprovedBy, ApprovalDate: $ApprovalDate, Creator: $Creator) {
    ...TvpFinalProgramFragment
  }
  }
  ${TvpFinalProgramFragment}
`;


export function usePublishTvpDraftProgramMutation() {
  return useMutation<{ publishTvpDraftProgram: TVP_Final_Program }, { Id: String; ApprovedBy: String; ApprovalDate: String; Creator: String}>(
    PUBLISH_Tvp_Draft_Program_MUTATION,
    {
      //refetchQueries: [{ query: GET_TVP_DRAFT_PROGRAMS }, 'getTvpDraftPrograms'],
      refetchQueries: [
        { query: GET_TVP_DRAFT_PROGRAMS, variables: { /* optional variables for draft program query */ } },
        //{ query: GET_TVP_FINAL_PROGRAMS, variables: { /* optional variables for final program query */ } },
      ],
    }
  );
}

///////////////////////
// const RETIRE_Tvp_Final_Program_MUTATION = gql`
//   mutation RetireTvpFinalProgram($Id: String, $Annotation: String, $Modifier: String) {
//   retireTvpFinalProgram(Id: $Id, Annotation: $Annotation, Modifier: $Modifier) {
//     ...TvpFinalProgramFragment
//   }
//   }
//   ${TvpFinalProgramFragment}
// `;

const RETIRE_Tvp_Final_Program_MUTATION = gql`
  mutation RetireTvpFinalProgram($Id: String, $Annotation: String, $Modifier: String) {
    retireTvpFinalProgram(Id: $Id, Annotation: $Annotation, Modifier: $Modifier)
  }
`;

export function useRetireTvpFinalProgramMutation() {
  return useMutation<{ retireTvpFinalProgram: TVP_Final_Program }, { Id: String; Annotation: String; Modifier: String}>(
    RETIRE_Tvp_Final_Program_MUTATION,
    {
      refetchQueries: [{ query: GET_TVP_FINAL_PROGRAMS }, 'getTvpFinalPrograms'],
    }
  );
}

const COPY_Tvp_Final_To_Draft_Program_MUTATION = gql`
  mutation copyTvpFinalToDraftProgram($Id: String, $FiscalYearStartDate: String,  $Creator: String) {
    copyTvpFinalToDraftProgram(Id: $Id, FiscalYearStartDate: $FiscalYearStartDate, Creator: $Creator) {
    ...TvpDraftProgramFragment
  }
  }
  ${TvpDraftProgramFragment}
`;

export function useCopyTvpFinalToDraftProgramMutation() {
  return useMutation<{ copyTvpFinalToDraftProgram: TVP_Draft_Program }, { Id: String; FiscalYearStartDate: String; Creator: String}>(
    COPY_Tvp_Final_To_Draft_Program_MUTATION,
    {
      refetchQueries: [{ query: GET_TVP_DRAFT_PROGRAMS }, 'getTvpDraftPrograms'],
    }
  );
}





