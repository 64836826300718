
import React, { useEffect, useState } from 'react';
import {Popconfirm, Modal, Form, Input, notification, Select, Popover, Button, Typography, Space, Divider } from 'antd';
import { RProgramInput, RVP_Draft_Program } from '../graphql/types';
import { Store } from 'antd/es/form/interface';

import { useQuery } from '@apollo/client';
import {GET_L_HAZARD_TYPE, GET_L_LIFE_CYCLE_STAGE, GET_L_PROGRAM } from '../graphql/queries';
import * as queries from '../graphql/queries';
import {
  useCreateRProgramMutation,
  useUpdateRProgramMutation,
} from '../hooks/useMutations';
import config from '../config';

const { TextArea } = Input;

type RvpDraftProgramFormModalProps = {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: RVP_Draft_Program, createMode: boolean) => void;
  initialValues: RVP_Draft_Program | null;
};

const RvpDraftProgramFormModal: React.FC<RvpDraftProgramFormModalProps> = ({
  visible,
  onCancel,
  onSubmit,
  initialValues,
}) => {
  const [form] = Form.useForm();

   // @ts-ignore
   const idTokenClaims = JSON.parse(localStorage.getItem(config.tokenClaimsKey));
   const userEmail = idTokenClaims.preferred_username;
   console.log(userEmail);

  const newInitialValues = initialValues ? {
    ...initialValues,
    event_category: initialValues.event_category ? initialValues.event_category.split(',').map(item => item.trim()) : [],
    hazard_type: initialValues.hazard_type ? initialValues.hazard_type.split(',').map(item => item.trim()) : [],
    resource_type: initialValues.resource_type ? initialValues.resource_type.split(',').map(item => item.trim()) : [],
    staff: initialValues.staff ? initialValues.staff.split(',').map(item => item.trim()) : [],
    row_modified_by: initialValues.idSk ? userEmail : "",
    
  } : {row_created_by: userEmail,};

  useEffect(() => {
    form.resetFields();
    if (newInitialValues) {
      form.setFieldsValue(newInitialValues);
    }
  }, [form, newInitialValues]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      // Convert multi-select field values into comma-separated string
      values.hazard_type = values.hazard_type ? values.hazard_type.join() : '';
      values.resource_type = values.resource_type ? values.resource_type.join() : '';
      values.event_category = values.event_category ? values.event_category.join() : '';
      values.staff = values.staff ? values.staff.join() : '';
      onSubmit(values, !initialValues);
    } catch (error) {
      console.log('Validation error:', error);
    }
  };


  // const { data: confidenceLevelData } = useQuery(GET_L_CONFIDENCE_LEVEL);
  // const confidenceLevels = confidenceLevelData?.lConfidences ?? [];
  // const confidenceLevelOptions = confidenceLevels.map((level: any) => ({
  //   label: level.confidence_level,
  //   value: level.confidence_level,
  // }));

  const { data: programData } = useQuery(GET_L_PROGRAM);
  const programs = programData?.lPrograms ?? [];
  const programOptions = programs.map((p: any) => ({
    label: p.program,
    value: p.program,
  }));

  const { data: lifeCycleData } = useQuery(GET_L_LIFE_CYCLE_STAGE);
  const lifeCycles = lifeCycleData?.lLifeCycles ?? [];
  const lifeCycleOptions = lifeCycles.map((l: any) => ({
    label: l.life_cycle_stage,
    value: l.life_cycle_stage,
  }));

  const { data: hazardData } = useQuery(GET_L_HAZARD_TYPE);
  const hazards = hazardData?.lHazards ?? [];
  const hazardOptions = hazards.map((h: any) => ({
    label: h.hazard_type,
    value: h.hazard_type,
  }));

  const { data: resourceData } = useQuery(queries.GET_L_RESOURCE_TYPE);
  const resources = resourceData?.lResources ?? [];
  const resourceOptions = resources.map((r: any) => ({
    label: r.resource_type,
    value: r.resource_type,
  }));

  const { data: eventData } = useQuery(queries.GET_L_EVENT_CATEGORY);
  const events = eventData?.lEvents ?? [];
  const eventOptions = events.map((e: any) => ({
    label: e.event_category,
    value: e.event_category,
  }));

  const { data: disciplineData } = useQuery(queries.GET_L_DISCIPLINE);
  const disciplines = disciplineData?.lDisciplines ?? [];
  const disciplineOptions = disciplines.map((d: any) => ({
    label: d.discipline + ' - ' + d.description,
    value: d.discipline,
  }));

  const { data: staffData } = useQuery(queries.GET_L_STAFF);
  const staffs = staffData?.lStaffs ?? [];
  const staffOptions = staffs.map((s: any) => ({
    label: s.staff,
    value: s.staff,
  }));

  // const currentYear = new Date().getFullYear();
  // const fisicalYearOptions = Array.from({ length: 8 }, (_, index) => ({
  //   value: (currentYear - 4 + index).toString() + "-04-01",
  //   label: (currentYear - 4 + index).toString() + "-04-01"
  // }));
  const currentYear = new Date().getFullYear();
  const startYear = Math.max(2021, currentYear - 4); // Ensure startYear is no earlier than 2021

  const fisicalYearOptions = Array.from({ length: 8 }, (_, index) => ({
    value: (startYear + index).toString() + "-04-01",
    label: (startYear + index).toString() + "-04-01"
  }));

  const [createRProgramName] = useCreateRProgramMutation();
  const [inactiveRProgramName] = useUpdateRProgramMutation()
  const [visiblePopover, setVisiblePopover] = useState(false);
  const handleVisiblePopoverChange = (visiblePopover:boolean) => {
    setVisiblePopover(visiblePopover);
  };
  const handleAddNewProgramName = async () => {
    try {
      const inputElement: any = document.getElementById('input-program');
      const inputValue = inputElement?.value;
      if (inputValue) {
        const myRProgramInput: RProgramInput = {
          program: inputValue,
          active:1
        };
        try {
          const { data } = await createRProgramName({ variables: { newRProgram: myRProgramInput } });
          if (data?.createRProgram) {
            notification.success({ message: 'New program name added.' });
            setVisiblePopover(false); 
          }else
          {notification.error({ message: 'Adding new program name failed!' });}
        } catch (error) {
          notification.error({ message: 'Adding new program name failed!' });
        }
      }
    } catch (error) {
      console.log('Validation error:', error);
    }
  };
  const [selectedValue, setSelectedValue] = useState<any>(null);

  const handleRemoveProgramName = async () => {
    try {
      if (selectedValue) {
        const myRProgramInput: RProgramInput = {
          program: selectedValue,
          active:0
        };
        try {
          const { data } = await inactiveRProgramName({ variables: { newRProgram: myRProgramInput } });
          if (data?.updateRProgram) {
            notification.success({ message: 'program name is turned inactive .' });
            setSelectedValue(null);
            setVisiblePopover(false); 
          }else
          {notification.error({ message: 'Turning program name inactive failed!' });}
        } catch (error) {
          notification.error({ message: 'Turning program name inactive failed!' });
        }
      }
    } catch (error) {
      console.log('Validation error:', error);
    }
  };
  const handleSelectChange = (value: any) => {
    setSelectedValue(value);
  };
  
  const contentPopover = (
    <div>
      <Divider style={{ margin: '8px 0' }} />
     <div style={{ marginBottom: '16px', paddingLeft: '16px' }}>
      <Space>
        <Typography.Text strong>Enter New Program Name:</Typography.Text>
        <Input id="input-program" style={{ width: 300 }} />
        <Button type="primary" size="small" onClick={handleAddNewProgramName}>
          Create
        </Button>
      </Space>
    </div>
    <div style={{paddingLeft: '16px' }}>
        <Space>
          <Typography.Text strong>Select Program Name:</Typography.Text>
          <Select onChange={handleSelectChange}
            style={{ width: 300 }}
            options={programOptions}
            value={selectedValue} />
          <Popconfirm
            title="Are you sure you want to delete this program name?"
            onConfirm={handleRemoveProgramName}
            okText="Yes"
            cancelText="No"
          >
            <Button danger type="primary" size="small" >
              Remove
            </Button>
          </Popconfirm>
  
        </Space>
    </div>
  </div>
  )

  return (
    <Modal
      title={initialValues ? 'Edit RV Draft Program' : 'Add RV Draft Program'}
      open={visible}
      onOk={handleOk}
      onCancel={onCancel}
      maskClosable={false}
      width={1000}
    //bodyStyle={{height: 700}}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={newInitialValues as Store}

      >
        <Form.Item style={{ display: 'inline-block' }}
          name="program"
          label={<Popover title = {"Manage Program Name List"} open = {visiblePopover} onOpenChange={handleVisiblePopoverChange} placement="topLeft" content={contentPopover} trigger="click">
            <Button type="link" size = "small" onClick={() => setVisiblePopover(true)}>Program</Button>
          </Popover>}
          rules={[{ required: true, message: 'Program is required' }]}
        >
          <Select
            style={{ width: 300 }}
            options={programOptions} />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', marginLeft: '30px' }}
          name="life_cycle_stage"
          label="Life Cycle Stage"
          rules={[{ required: true, message: 'Life Cycle Stage is required' }]}
        >
          <Select
            style={{ width: 170 }}
            options={lifeCycleOptions} />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', marginLeft: '30px' }}
          name="hazard_type"
          label="Hazard Type"
          rules={[{ required: true, message: 'Hazard type is required' }]}
        >
          <Select
            mode="multiple"
            style={{ width: 420 }}
            options={hazardOptions} />
        </Form.Item>
        <Form.Item
          name="resource_type"
          label="Resource Type"
          rules={[{ required: true, message: 'Resource type is required' }]}
        >
          <Select
            mode="multiple"
            //style={{ width: 420 }}
            options={resourceOptions} />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block' }}
          name="event_category"
          label="Event Category"
          rules={[{ required: true, message: 'Event category is required' }]}
        >
          <Select
            mode="multiple"
            style={{ width: 650 }}
            options={eventOptions} />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', marginLeft: '30px' }}
          name="discipline"
          label="Discipline"
          rules={[{ required: true, message: 'Discipline is required' }]}
        >
          <Select
            style={{ width: 270 }}
            options={disciplineOptions} />
        </Form.Item>
        <Form.Item name="variables_in_strata" label="Variables in Strata">
          <TextArea rows={1} />
        </Form.Item>
        <Form.Item name="extraction_details" label="Extraction Details">
          <TextArea rows={1} />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block' }}
          name="staff"
          label="Staff"
          rules={[{ required: true, message: 'Staff is required' }]}
        >
          <Select
            style={{ width: 420 }}
            mode="multiple"
            options={staffOptions} />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', marginLeft: '30px' }}
          name="fiscal_year_start_date"
          label="Fiscal Year Start Date"
          rules={[{ required: true, message: 'Fiscal year is required' }]}>
          <Select
            style={{ width: 140 }}
            options={fisicalYearOptions} />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', marginLeft: '30px' }}
          name="comment" label="Comments/changes">
          {/* <Input  style={{ width: 330 }} /> */}
          <TextArea rows={1} style={{ width: 330 }} />
        </Form.Item>
        <Form.Item name="idSk" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="row_created_by" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="row_modified_by" hidden>
          <Input />
        </Form.Item>
      </Form>

    </Modal>
  );
};

export default RvpDraftProgramFormModal;

