
import React, { useState } from 'react';
import { Table, Button, Space, Tooltip, Select } from 'antd';
import { RVP_Draft_Program, RVP_Draft_Program_Strata, RVP_Draft_Stratum } from '../graphql/types';
import { EditOutlined, DeleteOutlined, CloudUploadOutlined, MinusOutlined, PlusOutlined, PlusSquareOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { SortOrder } from 'antd/es/table/interface';
import './RvpProgramTable.css';
import RvpDraftProgramsExport from './RvpDraftProgramsExport';
import config from '../config';

type RvpDraftProgramTableProps = {
  rvpDraftPrograms: RVP_Draft_Program_Strata[];
  onEdit: (rvpDraftProgram: RVP_Draft_Program) => void;
  onDelete: (rvpDraftProgram: RVP_Draft_Program) => void;
  onEditStratum: (rvpDraftStratum: RVP_Draft_Stratum) => void;
  onDeleteStratum: (rvpDraftStratum: RVP_Draft_Stratum) => void;
  onCreateStratum: (rvpDraftProgram: RVP_Draft_Program) => void;
  onPublish: (rvpDraftProgram: RVP_Draft_Program) => void;
};


const RvpDraftProgramTable: React.FC<RvpDraftProgramTableProps> = ({ rvpDraftPrograms, onEdit, onDelete, onEditStratum, onDeleteStratum, onCreateStratum, onPublish }) => {

  //const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [hideColumns, setHideColumns] = useState(true);

  // @ts-ignore
  const userRoles: string[] = JSON.parse( localStorage.getItem(config.rolesKey));

  // const getColumnSearchProps = (dataIndex: string) => ({
  //   filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
  //         onPressEnter={() => confirm()}
  //         style={{ width: 188, marginBottom: 8, display: 'block' }}
  //       />
  //       <div style={{ display: 'flex', justifyContent: 'space-between' }}>
  //         <button type="button" onClick={() => clearFilters()}>
  //           Reset
  //         </button>
  //         <button type="button" onClick={() => confirm()}>
  //           Filter
  //         </button>
  //       </div>
  //     </div>
  //   ),
  //   filterIcon: (filtered: boolean) => <span>{filtered ? '🔍' : '🔎'}</span>,
  //   onFilter: (value: string | number | boolean, record: any) =>
  //     typeof value === 'string' && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  //   onFilterDropdownOpenChange: (visible: boolean) => {
  //     if (visible) {
  //       setSearchedColumn(dataIndex);
  //     }
  //   },
  //   render: (text: string, record: any) => (
  //     searchedColumn === dataIndex ? (
  //       <span >{text}</span>
  //     ) : (
  //       text
  //     )
  //   ),
  // });
  const handleDownloadExcel = () => {
    RvpDraftProgramsExport(rvpDraftPrograms); // Call the exportToExcel function with your data and filename
  };

  const uniquePrograms = Array.from(new Set(rvpDraftPrograms.map((program) => program.program)));

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(value) => setSelectedKeys(value ? [value] : [])}
          //onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        >
         {uniquePrograms.map((program) => (
        <Select.Option key={program} value={program}>
          {program}
        </Select.Option>
      ))}
        </Select>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button type="button" onClick={() => clearFilters()}>
            Reset
          </button>
          <button type="button" onClick={() => confirm()}>
            Filter
          </button>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => <span>{filtered ? '🔍' : '🔎'}</span>,
    onFilter: (value: string | number | boolean, record: any) =>
      typeof value === 'string' && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setSearchedColumn(dataIndex);
      }
    },
    render: (text: string, record: any) => (
      searchedColumn === dataIndex ? (
        <span>{text}</span>
      ) : (
        text
      )
    ),
  });
  

  const columns = [
    {
      title: 'Program',
      dataIndex: 'program',
      key: 'program',
      width: 110,
      sorter: (a: RVP_Draft_Program_Strata, b: RVP_Draft_Program_Strata) => a.program.localeCompare(b.program),
      sortDirections: ['ascend', 'descend'] as SortOrder[],
      ...getColumnSearchProps('program'),
      
    },
    {
      title: 'Hazard Type',
      dataIndex: 'hazard_type',
      key: 'hazard_type',
      ellipsis: true,
      //width: 110,
    },
    {
      title: 'Resource Type',
      dataIndex: 'resource_type',
      key: 'resource_type',
      ellipsis: true,
      width: 100,
      // ellipsis: {
      //   showTitle: false,
      // },
      // render: (resource_type:string) => (
      //   <Tooltip placement="topLeft" title={resource_type}>
      //     {resource_type}
      //   </Tooltip>
      // ),
    },
    {
      title: 'Life Cycle Stage',
      dataIndex: 'life_cycle_stage',
      key: 'life_cycle_stage',
      //width: 110,
    },
    {
      title: 'Event Category',
      dataIndex: 'event_category',
      key: 'event_category',
      ellipsis: true,
      //width: 110,
    },
    {
      title: 'Discipline',
      dataIndex: 'discipline',
      key: 'discipline',
      width: 95,
    },
    {
      title: 'Population',
      dataIndex: 'population',
      key: 'population',
      //width: 70,
      align: 'right' as const,
      ellipsis: true,
    },
    {
      title: 'Sample Size',
      dataIndex: 'sample_size_total',
      key: 'sample_size_total',
      align: 'right' as const,
      //width: 60,
      ellipsis: true,
    },
    {
      title: 'Average Confidence Level (%)',
      dataIndex: 'average_confidence_level',
      key: 'average_confidence_level',
      align: 'right' as const,
      //width: 60,
      ellipsis: true,
      render: (value: number) => {
        return value ? value.toFixed(2) : value;
      }
    },
    {
      title: 'Variables in Strata',
      dataIndex: 'variables_in_strata',
      key: 'variables_in_strata',
      width: 210,
    },
    {
      title: 'Extraction Details',
      dataIndex: 'extraction_details',
      key: 'extraction_details',
      width: 110,
    },
    {
      title: 'Staff',
      dataIndex: 'staff',
      key: 'staff',
      ellipsis: true,
      //width: 75,
    },
    {
      title: 'FY Start Date',
      dataIndex: 'fiscal_year_start_date',
      key: 'fiscal_year_start_date',
      //width: 110,
    },
    {
      title: 'Created By',
      dataIndex: 'row_created_by',
      key: 'row_created_by',
      //width: 85,
      hidden: hideColumns,
      ellipsis: true,
    },
    {
      title: 'Create Date',
      dataIndex: 'row_create_date_time',
      key: 'row_create_date_time',
      //width: 75,
      ellipsis: true,
      hidden: hideColumns,
      render: (dateString: string) => moment(Number(dateString)).format('YYYY-MM-DD')
    },
    {
      title: 'Modified By',
      dataIndex: 'row_modified_by',
      key: 'row_modified_by',
      //width: 90,
      ellipsis: true,
      hidden: hideColumns,
    },
    {
      title: 'Modify Date',
      dataIndex: 'row_modify_date_time',
      key: 'row_modify_date_time',
      //width: 80,
      ellipsis: true,
      render: (dateString: string) => dateString ? moment(Number(dateString)).format('YYYY-MM-DD') : '',
      hidden: hideColumns,
    },
    {
      title: ( <Space>
        <Button
          type="text"
          size="small"
          onClick={() => setHideColumns(!hideColumns)}
        >
          {hideColumns ? '>' : '<'}
        </Button>
        <Button type="ghost" shape="circle" icon={<DownloadOutlined />} onClick={handleDownloadExcel}>
          
        </Button>
      </Space>),
      key: 'action',
      //width: 110,
      render: (_: any, record: RVP_Draft_Program, maxStratumValueInDb:number) => (
        <>
          <Space>
          
            <Tooltip title="Click to edit">
              <Button
                style={{ backgroundColor: '#ffbf00' }}
                type="primary"
                size="small"
                icon={<EditOutlined />}
                onClick={() => onEdit(record)}
                disabled={!userRoles.includes('Writer')}
              >

              </Button>
            </Tooltip>
          
          
            <Tooltip title="Publish">
              <Button

                style={{ backgroundColor: 'green' }}
                type="primary"
                size="small"
                icon={<CloudUploadOutlined />}
                onClick={() => onPublish(record)}
                disabled={!userRoles.includes('Publisher')}
              >
              </Button>
            </Tooltip>
          
          </Space>
          <Space style={{ margin: '3px 0' }}>
          
            <Tooltip title="Click to delete">
              <Button
                danger
                type="primary"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => onDelete(record)}
                disabled={!userRoles.includes('Writer')}
              >
              </Button>
            </Tooltip>
               
              
            <Tooltip title="Click to add stratum">
              <Button
                type="primary"
                size="small"
                icon={<PlusSquareOutlined />}
                onClick={() => onCreateStratum(record)}
                disabled={!userRoles.includes('Writer')}
              >
              </Button>
            </Tooltip>
            
          </Space>

         
        </>
      ),
    },

  ].filter(item => !item.hidden);;


  // define the columns of the children table
  const childColumns = [
    {
      title: 'Stratum Id : Name',
      key: 'stratum_id_name',
      render: (text: any, record: { stratum_id: any; annotation: any; }) => `${record.stratum_id} : ${record.annotation}`,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Operator',
      dataIndex: 'operator',
      key: 'operator',
    },
    {
      title: 'Activity',
      dataIndex: 'activity',
      key: 'activity',
    },    

    {
      title: 'Stratum Population (Volume)',
      dataIndex: 'stratum_population',
      key: 'stratum_population',
    },
    {
      title: 'Sample Size',
      dataIndex: 'sample_size',
      key: 'sample_size',
    },
    {
      title: 'Confidence Level (%)',
      dataIndex: 'confidence_level',
      key: 'confidence_level',
    },
    {
      title: 'Percent Of Population (%)',
      dataIndex: 'percent_of_population',
      key: 'percent_of_population',
      render: (value: number) => {
        return value ? value.toFixed(2) : value;
      }
    },
    {
      title: 'Extraction Method',
      dataIndex: 'extraction_method',
      key: 'extraction_method',
    },
    {
      title: 'Extraction Frequency',
      dataIndex: 'extraction_frequency',
      key: 'extraction_frequency',
    },
    {
      title: 'Samples Per Extraction',
      dataIndex: 'samples_per_extraction',
      key: 'samples_per_extraction',
    },
    {
      title: 'Sample Extraction Rate',
      dataIndex: 'sample_extraction_rate',
      key: 'sample_extraction_rate',
      render: (value: number) => {
        return value ? value.toFixed(0) : value;
      }
    },
    {
      title: 'Statistical Statement',
      dataIndex: 'statistical_statement',
      key: 'statistical_statement',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: RVP_Draft_Stratum) => (
        <>
          <Space>
            <Tooltip title="Click to edit">
              <Button
                style={{ backgroundColor: '#ffbf00' }}
                type="primary"
                size="small"
                icon={<EditOutlined />}
                onClick={() => onEditStratum(record)}
                disabled={!userRoles.includes('Writer')}
              >

              </Button>

            </Tooltip>
          </Space>
          <Space style={{ margin: '3px 0' }}>
            <Tooltip title="Click to delete">
              <Button
                danger
                type="primary"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => onDeleteStratum(record)}
                disabled={!userRoles.includes('Writer')}
              >
              </Button>
            </Tooltip>

          </Space>

          <Space>

          </Space>
        </>
      ),


    },
  ];

  // define the function to render the children table
  const expandedRowRender = (record: RVP_Draft_Program_Strata) => {
    return (
      <Table
        columns={childColumns}
        dataSource={record.strata}
        rowKey="idSk"
        pagination={false} // remove pagination from the children table
        className="child-table" // add a class to the child table
      />
    );
  };

  const rowExpandable = (record: RVP_Draft_Program_Strata) => {
    return record.strata.length > 0; // only expand rows with children
  };


  return <Table
    //scroll={{ x: "max-content" }}
    scroll={{ x: "scroll" }}
    //scroll={{ x: 2000 }}
    columns={columns}
    dataSource={rvpDraftPrograms}
    pagination={{ pageSize: 100 }}
    rowKey="idSk"
    expandable={{
      expandedRowRender, rowExpandable,
      expandIcon: ({ expanded, onExpand, record }) =>
        expanded ? (
          <MinusOutlined
            style={{ fontSize: '20px' }}
            onClick={(e) => onExpand(record, e)}
          />
        ) : (
          <PlusOutlined
            style={{ fontSize: '20px' }}
            onClick={(e) => onExpand(record, e)}
          />
        ),
    }} />;
};

export default RvpDraftProgramTable;
