//const response =  fetch("http://localhost:5001/graphql", {
const response =  fetch("/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: `
      query  {
        getClientVariables {
          tenantId
          clientId
          graphQLEndpoint
        }
      }
      `,
    }),
  })
  .then(res => {return res.json()})

export default response;