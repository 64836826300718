import React, { useRef, useState } from 'react';
import { notification } from 'antd';
import { ExcelReader } from '../../components/ExcelReader';
import {
  useCreateRvpDraftProgramMutation,
  useCreateRvpDraftStratumMutation,
} from '../../hooks/useMutations';

function ExcelLoader() {
  const [loading, setLoading] = useState(false); // State to track loading status

  const [createRvpDraftProgram] = useCreateRvpDraftProgramMutation();
  const [createRvpDraftStratum] = useCreateRvpDraftStratumMutation();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return; // handle null case
    try {
      setLoading(true); // Start loading

      const programs_strata = await ExcelReader(file);

      let successCount = 0;

      try {
        const { data } = await createRvpDraftProgram({ variables: { newRvpDraftProgram: programs_strata.programs[0] } });
        if (data?.createRvpDraftProgram) {
          notification.success({ message: 'Rvp draft program added successfully!' });

          const strata = programs_strata.strata;
          for (const stratum of strata) {
            try {
              const { data } = await createRvpDraftStratum({ variables: { newRvpDraftStratum: stratum } });
              if (data?.createRvpDraftStratum) {
                successCount++;
              }
            } catch (error) {
              const errorMessage = (error as Error).message; // Cast 'error' to 'Error' type
              console.error(errorMessage);
              notification.error({ message: `Adding rvp draft stratum failed: ${errorMessage}` });
            }
          }
        }
      } catch (error) {
        const errorMessage = (error as Error).message; // Cast 'error' to 'Error' type
        console.error(errorMessage);
        notification.error({ message: 'Adding rvp draft program failed!', description: errorMessage });
      }

      if (successCount > 0) {
        notification.success({ message: `${successCount} Rvp draft strata added successfully!` });
      } else {
        notification.error({ message: 'Adding rvp draft strata failed!' });
      }

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
        const form = fileInputRef.current.form;
        if (form) {
          form.reset();
        }
      }
    } catch (error) {
      const errorMessage = (error as Error).message; // Cast 'error' to 'Error' type
      console.error(errorMessage);
      notification.error({ message: 'Adding rvp draft programs/strata failed!', description: errorMessage });
    } finally {
      setLoading(false); // Stop loading
    }
  }

  return (
    <div>
      <form>
        <input type="file" onChange={handleFileUpload} ref={fileInputRef} style={{ cursor: loading ? 'wait' : 'auto' }} />
      </form>
    </div>
  );
}

export default ExcelLoader;
