import React, { useState, useEffect } from 'react';
import { Table, Button, DatePicker, message, Typography } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_WELL_POPULATION, UPDATE_RVP_DRAFT_STRATUM_POPULATION } from '../../graphql/queries';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';

type SamplePopulation = {
  StratumId: string;
  Description: string;
  StratumPopulation: number;
};

const WellPopulation: React.FC = () => {
  const [samplePopulations, setSamplePopulations] = useState<SamplePopulation[]>(() => {
    const storedPopulations = localStorage.getItem('samplePopulations');
    return storedPopulations ? JSON.parse(storedPopulations) : [];
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [cutOffDate, setCutOffDate] = useState<string | null>(dayjs().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'));
  const [timestamp, setTimestamp] = useState<string | null>(() => {
    const storedTimestamp = localStorage.getItem('timestamp');
    return storedTimestamp ? storedTimestamp : null;
  });

  const [fetchData, { data, loading: queryLoading, error: queryError }] = useLazyQuery(GET_WELL_POPULATION, {
    fetchPolicy: 'network-only',
  });

  const [updateRvpDraftStratum] = useMutation(UPDATE_RVP_DRAFT_STRATUM_POPULATION);

  useEffect(() => {
    if (data && data.wellPopulation) {
      const currentTimestamp = dayjs().format('YYYY-MM-DD HH:mm:ss');
      setSamplePopulations(data.wellPopulation);
      setTimestamp(currentTimestamp);
      setLoading(false);
      localStorage.setItem('samplePopulations', JSON.stringify(data.wellPopulation));
      localStorage.setItem('timestamp', currentTimestamp);
    }
  }, [data]);

  useEffect(() => {
    if (queryError) {
      setError(queryError.message);
      setLoading(false);
    }
  }, [queryError]);

  const handleFetchData = () => {
    if (cutOffDate && !loading) {
      setError(null);
      setSamplePopulations([]);
      setLoading(true);
      fetchData({ variables: { cutOffDate } })
        .then(() => setLoading(false))
        .catch(err => {
          setError(err.message);
          setLoading(false);
        });
    } else if (!cutOffDate) {
      setError("Please select a cutoff date.");
    }
  };

  const handleDateChange = (date: any, dateString: string) => {
    setCutOffDate(dateString);
    setError(null);
    setSamplePopulations([]);
    setTimestamp(null);
    localStorage.removeItem('samplePopulations');
    localStorage.removeItem('timestamp');
  };

  const handleUpdatePopulation = () => {
    if (!samplePopulations.length) {
      message.error("No data available to update.");
      return;
    }

    setLoading(true);

    const updatePromises = samplePopulations.map(population =>
      updateRvpDraftStratum({
        variables: {
          stratumId: population.StratumId,
          population: population.StratumPopulation
        },
      })
    );

    Promise.all(updatePromises)
      .then(results => {
        const failedUpdates = results
          .map((result, index) => (!result.data.updateRvpDraftStratumPopulation ? samplePopulations[index].StratumId : null))
          .filter(stratumId => stratumId !== null);
        
        if (failedUpdates.length === 0) {
          message.success("Population updated successfully.");
        } else {
          message.error(`Some updates failed for Stratum IDs: ${failedUpdates.join(', ')}`);
        }
      })
      .catch(err => {
        message.error("Error updating population: " + err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<SamplePopulation> = [
    {
      title: 'Stratum ID',
      dataIndex: 'StratumId',
      key: 'StratumId',
      width: 150,
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description',
      width: 200,
    },
    {
      title: 'Population',
      dataIndex: 'StratumPopulation',
      key: 'StratumPopulation',
      align: 'right',
      width: 150,
    },
  ];

  return (
    <div>
      <Typography.Title level={4}>RVP Well Population</Typography.Title>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px', width: '1000px' }}>
        <div style={{ display: 'flex', gap: '8px' }}>
          <DatePicker 
            onChange={handleDateChange} 
            defaultValue={dayjs().subtract(1, 'year').endOf('year')} 
            format='YYYY-MM-DD'
            style={{ width: '150px' }}
          />
          <Button type="primary" onClick={handleFetchData} loading={loading} style={{ marginRight: '8px' }}>
            Calculate Stratum Population
          </Button>
          <Button
            type="primary"
            onClick={handleUpdatePopulation}
            disabled={loading || !samplePopulations.length}
            style={{
              backgroundColor: 'orange',
              borderColor: 'orange',
              color: loading || !samplePopulations.length ? 'rgba(0, 0, 0, 0.75)' : 'white',
            }}
          >
            Update Population in Draft Table
          </Button>
          {timestamp && <Typography.Text style={{ marginLeft: '100px' }}>Data generated on: {timestamp}</Typography.Text>}
        </div>
      </div>
      {loading && <div>Loading... Please wait for about 5 minutes while the program is running.</div>}
      {error && <div>Error: {error}</div>}
      {!loading && !error && (
        <Table 
          columns={columns} 
          dataSource={samplePopulations} 
          rowKey="StratumId"
          pagination={false} 
          style={{ width: '1000px', marginLeft: 0 }}
        />
      )}
    </div>
  );
};

export default WellPopulation;
