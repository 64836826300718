import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Space, Statistic, Typography } from "antd";

import { useQuery } from '@apollo/client';
import { GET_RVP_FINAL_PROGRAMS_STRATA, GET_TVP_FINAL_PROGRAMS } from '../../graphql/queries';
import { RVP_Final_Program_Strata, TVP_Final_Program } from "../../graphql/types";

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip } from 'recharts';

function Dashboard() {
  const { loading: loadingRVP, error: errorRVP, data: dataRVP } = useQuery(GET_RVP_FINAL_PROGRAMS_STRATA);
  const { loading: loadingTVP, error: errorTVP, data: dataTVP } = useQuery(GET_TVP_FINAL_PROGRAMS);

  if (loadingRVP || loadingTVP) {
    return <div>Loading...</div>;
  }

  if (errorRVP || errorTVP) {
    return <div>Error: {errorRVP ? errorRVP.message : errorTVP?.message}</div>;
  }

  const rvpFinalPrograms = dataRVP?.rvpFinalProgramsStrata ?? [];
  // Find the latest date in the dataset
  const latestDate = rvpFinalPrograms.reduce((latest:any, program:any) => {
    if (program.fiscal_year_start_date > latest) {
      return program.fiscal_year_start_date;
    }
    return latest;
  }, '1900-01-01'); // Initialize with a very old date
  // Filter programs based on the latest date
  const filteredPrograms = rvpFinalPrograms.filter((program: RVP_Final_Program_Strata) => program.fiscal_year_start_date === latestDate);
  //const filteredPrograms = rvpFinalPrograms.filter((program: RVP_Final_Program_Strata) => program.fiscal_year_start_date === '2023-04-01');
  const totalPrograms = filteredPrograms.length;
  const totalStrata = filteredPrograms.reduce((sum: number, program: RVP_Final_Program_Strata) => sum + program.strata.length, 0);
  const totalSampleSize = filteredPrograms.reduce((sum: number, program: RVP_Final_Program_Strata) => sum + program.sample_size_total, 0);
  const totalPopulation = filteredPrograms.reduce((sum: number, program: RVP_Final_Program_Strata) => sum + program.population, 0);

  const groupedData = filteredPrograms.reduce((result: any[], program: RVP_Final_Program_Strata) => {
    const existingItem = result.find(item => item.discipline === program.discipline);
    if (existingItem) {
      existingItem.Population += program.population;
      existingItem.Sample_Size += program.sample_size_total;
    } else {
      result.push({
        Discipline: program.discipline,
        Population: program.population,
        Sample_Size: program.sample_size_total,
      });
    }
    return result;
  }, []);

  const populationData = groupedData.map((item: any) => ({
    Discipline: item.Discipline,
    Population: item.Population,
  }));

  const sampleSizeData = groupedData.map((item: any) => ({
    Discipline: item.Discipline,
    Sample_Size: item.Sample_Size,
  }));


  const tvpFinalPrograms = dataTVP?.tvpFinalPrograms ?? [];
  // Find the latest date in the dataset
  const latestDateTVP = tvpFinalPrograms.reduce((latest:any, program:any) => {
    if (program.FiscalYearStartDate > latest) {
      return program.FiscalYearStartDate;
    }
    return latest;
  }, '1900-01-01'); // Initialize with a very old date
  // Filter programs based on the latest date
  const filteredProgramsTVP = tvpFinalPrograms.filter((program: TVP_Final_Program) => program.FiscalYearStartDate === latestDateTVP);
  const totalProgramsTVP = filteredProgramsTVP.length;
  const totalVerificationTVP = filteredProgramsTVP.reduce((sum: number, program: TVP_Final_Program) => sum + program.NumberOfVerification, 0);
  const totalWorkdayTVP = filteredProgramsTVP.reduce((sum: number, program: TVP_Final_Program) => sum + program.NumberOfWorkday, 0);

  return <div>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography.Title level={3} style={{ marginRight: '10px' }}>
      Verification Programs Summary (FY Start Date: {latestDate})
      </Typography.Title>
      <Typography.Title level={4} style={{ fontStyle: 'italic' }}>
        (see Tableau report for details)
      </Typography.Title>
    </div>
    <Space>
      <DashboardCard icon={<CheckOutlined
        style={{
          color: 'green',
          backgroundColor: "rgba(0, 255, 0, 0.10)",
          borderRadius: 20,
          fontSize: 24,
          padding: 8
        }} />}
        title={<Typography.Text strong style={{ fontSize: 14 }}>RV Programs</Typography.Text>}
        value={totalPrograms} />
      <DashboardCard icon={<CheckOutlined
        style={{
          color: 'green',
          backgroundColor: "rgba(0, 255, 0, 0.25)",
          borderRadius: 20,
          fontSize: 24,
          padding: 8
        }} />}
        title={<Typography.Text strong style={{ fontSize: 14 }}>RV Strata</Typography.Text>}
        value={totalStrata} />
      <DashboardCard icon={<CheckOutlined
        style={{
          color: 'green',
          backgroundColor: "rgba(0, 255, 0, 0.5)",
          borderRadius: 20,
          fontSize: 24,
          padding: 8
        }} />}
        title={<Typography.Text strong style={{ fontSize: 14 }}>RV Samples</Typography.Text>}
        value={totalSampleSize} />
      <DashboardCard icon={<CheckOutlined
        style={{
          color: 'green',
          backgroundColor: "rgba(0, 255, 0, 0.75)",
          borderRadius: 20,
          fontSize: 24,
          padding: 8
        }} />}
        title={<Typography.Text strong style={{ fontSize: 14 }}>RV Population</Typography.Text>}
        value={totalPopulation} />
      <DashboardCard icon={<PlusOutlined
        style={{
          color: 'red',
          backgroundColor: "rgba(255, 0, 0, 0.10)",
          borderRadius: 20,
          fontSize: 24,
          padding: 8
        }} />}
        title={<Typography.Text strong style={{ fontSize: 14 }}>TV Programs</Typography.Text>}
        value={totalProgramsTVP} />

      <DashboardCard icon={<PlusOutlined
        style={{
          color: 'red',
          backgroundColor: "rgba(255, 0, 0, 0.25)",
          borderRadius: 20,
          fontSize: 24,
          padding: 8
        }} />}
        title={<Typography.Text strong style={{ fontSize: 14 }}>TV Numbers</Typography.Text>}
        value={totalVerificationTVP} />
      <DashboardCard icon={<PlusOutlined
        style={{
          color: 'red',
          backgroundColor: "rgba(255, 0, 0, 0.50)",
          borderRadius: 20,
          fontSize: 24,
          padding: 8
        }} />}
        title={<Typography.Text strong style={{ fontSize: 14 }}>TV Workdays</Typography.Text>}
        value={totalWorkdayTVP} />
    </Space>

    <div style={{ marginTop: 20 }}>
    <div style={{ margin: '40px' }}>
        <h3>Sample Size</h3>
        <BarChart width={600} height={250} data={sampleSizeData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Discipline" />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign="top" />
          <Bar dataKey="Sample_Size" fill="#86A620"/>
        </BarChart>
      </div>
      <div style={{ margin: '40px' }}>
        <h3>Population</h3>
        <BarChart width={600} height={250} data={populationData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Discipline" />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign="top" />
          <Bar dataKey="Population" fill="#6C7608" />
        </BarChart>
      </div>
      
    </div>

  </div>
}

function DashboardCard({ title, value, icon }: any) {

  return <div>
    <Card>
      <Space direction="horizontal">
        {icon}
        <Statistic title={title} value={value}></Statistic>
      </Space>
    </Card>
  </div>
}

export default Dashboard