import {Typography} from "antd";
import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";

export const WelcomeName = () => {

    const { instance } = useMsal();
    const [userName, setUserName] = useState('')

    useEffect(() => { 
        const currentAccount = instance.getActiveAccount();

        if (currentAccount) {
            setUserName(currentAccount.name);
        }
    }, [instance]);

    return <Typography variant="h6">Welcome <b>{userName}</b></Typography>;
};