import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter } from "react-router-dom";
import config from "./config";

import {
  PublicClientApplication,
  EventType,
  LogLevel,
  Configuration,
} from "@azure/msal-browser";

import response from "./authConfig";

response.then((res) => {
  //console.log("res.data.getClientVariables.clientId: " + JSON.stringify(res));

  // Config object to be passed to Msal on creation
  const msalConfig: Configuration = {
    auth: {
      clientId: res.data.getClientVariables.clientId,
      authority:"https://login.microsoftonline.com/" + res.data.getClientVariables.tenantId,
      redirectUri: "/",
      postLogoutRedirectUri: "/",
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPII) => {
          console.log(message);
        },
        logLevel: LogLevel.Info,
      },
    },
  };

  const pca = new PublicClientApplication(msalConfig);

  const accounts = pca.getAllAccounts();
  if (accounts.length > 0) {
    pca.setActiveAccount(accounts[0]);
  }

  pca.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      // @ts-ignore
      pca.setActiveAccount(event.payload.account);
      // @ts-ignore
      localStorage.setItem(config.tokenClaimsKey, JSON.stringify(event.payload.account.idTokenClaims));
      // @ts-ignore
      localStorage.setItem(config.rolesKey, JSON.stringify(event.payload.account.idTokenClaims.roles));
      // @ts-ignore
      localStorage.setItem(config.tokenKey, JSON.stringify(event.payload.accessToken));
    }
  });

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App
          msalInstance={pca}
          graphQLEndpoint={res.data.getClientVariables.graphQLEndpoint}
        />
      </BrowserRouter>
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
