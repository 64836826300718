
import React, { useEffect , useRef} from 'react';
import {Button, InputNumber, DatePicker, Row, Col, Checkbox, Radio, Modal, Form, Input, Select, Divider } from 'antd';
import { TVP_Draft_Program } from '../graphql/types';
import { Store } from 'antd/es/form/interface';

import { useQuery } from '@apollo/client';

import * as queries from '../graphql/queries';

import config from '../config';

//import moment from 'moment';

import dayjs from 'dayjs';

import ReactToPrint, { useReactToPrint } from 'react-to-print';

const { TextArea } = Input;

type TvpDraftProgramFormModalProps = {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: TVP_Draft_Program, createMode: boolean) => void;
  initialValues: TVP_Draft_Program | null;
};

const TvpDraftProgramFormModal: React.FC<TvpDraftProgramFormModalProps> = ({
  visible,
  onCancel,
  onSubmit,
  initialValues,
}) => {
  const [form] = Form.useForm();
  // @ts-ignore
  const userRoles: string[] = JSON.parse( localStorage.getItem(config.rolesKey));

   // @ts-ignore
   const idTokenClaims = JSON.parse(localStorage.getItem(config.tokenClaimsKey));
   const userEmail = idTokenClaims.preferred_username;
   console.log(userEmail);

  const newInitialValues = initialValues ? {
    ...initialValues,
    HazardType: initialValues.HazardType ? initialValues.HazardType.split(',').map(item => item.trim()) : [],
    ResourceType: initialValues.ResourceType ? initialValues.ResourceType.split(',').map(item => item.trim()) : [],
    Staff: initialValues.Staff ? initialValues.Staff.split(',').map(item => item.trim()) : [],
    Modifier: initialValues.Id ? userEmail : "",
    PreferredStartDate: initialValues.PreferredStartDate ? dayjs(Number(initialValues.PreferredStartDate)) : null,
    PreferredEndDate: initialValues.PreferredEndDate ? dayjs(Number(initialValues.PreferredEndDate)) : null,
    CreationInstant: initialValues.CreationInstant ? dayjs(Number(initialValues.CreationInstant)) : null,
    ModificationInstant: initialValues.ModificationInstant ? dayjs(Number(initialValues.ModificationInstant)) : null,
  } : {Creator: userEmail,};

  
  useEffect(() => {
    form.resetFields();
    if (newInitialValues) {
      form.setFieldsValue(newInitialValues);
    }
  }, [form, newInitialValues]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      // Convert multi-select field values into comma-separated string
      values.HazardType = values.HazardType ? values.HazardType.join() : '';
      values.ResourceType = values.ResourceType ? values.ResourceType.join() : '';
      values.Staff = values.Staff ? values.Staff.join() : '';
      delete values.ModificationInstant;
      delete values.CreationInstant;
      onSubmit(values, !initialValues);
    } catch (error) {
      console.log('Validation error:', error);
    }
  };


  // const { data: confidenceLevelData } = useQuery(GET_L_CONFIDENCE_LEVEL);
  // const confidenceLevels = confidenceLevelData?.lConfidences ?? [];
  // const confidenceLevelOptions = confidenceLevels.map((level: any) => ({
  //   label: level.confidence_level,
  //   value: level.confidence_level,
  // }));


  const { data: lifeCycleData } = useQuery(queries.GET_L_LIFE_CYCLE_STAGE);
  const lifeCycles = lifeCycleData?.lLifeCycles ?? [];
  const lifeCycleOptions = lifeCycles.map((l: any) => ({
    label: l.life_cycle_stage,
    value: l.life_cycle_stage,
  }));

  const { data: hazardData } = useQuery(queries.GET_L_HAZARD_TYPE);
  const hazards = hazardData?.lHazards ?? [];
  const hazardOptions = hazards.map((h: any) => ({
    label: h.hazard_type,
    value: h.hazard_type,
  }));

  const { data: resourceData } = useQuery(queries.GET_L_RESOURCE_TYPE);
  const resources = resourceData?.lResources ?? [];
  const resourceOptions = resources.map((r: any) => ({
    label: r.resource_type,
    value: r.resource_type,
  }));


  const { data: disciplineData } = useQuery(queries.GET_L_DISCIPLINE);
  const disciplines = disciplineData?.lDisciplines ?? [];
  const disciplineOptions = disciplines.map((d: any) => ({
    label: d.discipline + ' - ' + d.description,
    value: d.discipline,
  }));

  const { data: staffData } = useQuery(queries.GET_L_STAFF);
  const staffs = staffData?.lStaffs ?? [];
  const staffOptions = staffs.map((s: any) => ({
    label: s.staff,
    value: s.staff,
  }));


  const { data: reasonData } = useQuery(queries.GET_L_REASON);
  const reasons = reasonData?.lReasons ?? [];
  // const reasonOptions = reasons.map((r: any) => ({
  //   label: r.reason + r.annotation,
  //   value: r.reason,
  // }));
  const reasonOptions = reasons.map((r: any) => ({
    label: (
      <span style={{ fontWeight: 550 }}>
          {r.reason}&nbsp;&nbsp;&nbsp;
          <span style={{ color: 'hsl(210, 50%, 50%)' }}>{r.annotation}</span>
      </span>
    ),
    value: r.reason,
  }));

  const { data: intentData } = useQuery(queries.GET_L_INTENT);
  const intents = intentData?.lIntents ?? [];
  const intentOptions = intents.map((s: any) => ({
    label: <span style={{ fontWeight: 550 }}>{s.intent}</span>,
    value: s.intent,
  }));

  const { data: riskData } = useQuery(queries.GET_L_RISK);
  const risks = riskData?.lRisks ?? [];
  const riskOptions = risks.map((r: any) => ({
    label: (
      <span style={{ fontWeight: 550 }}>
          {r.risk}&nbsp;&nbsp;&nbsp;
          <span style={{ color: 'hsl(210, 50%, 50%)' }}>{r.annotation}</span>
      </span>
    ),
    value: r.risk,
  }));

  const { data: consequenceData } = useQuery(queries.GET_L_CONSEQUENCE);
  const consequences = consequenceData?.lConsequences ?? [];
  const consequenceOptions = consequences.map((r: any) => ({
    label: (
      <span style={{ fontWeight: 550 }}>
          {r.consequence}&nbsp;&nbsp;&nbsp;
          <span style={{ color: 'hsl(210, 50%, 50%)' }}>{r.annotation}</span>
      </span>
    ),
    value: r.consequence,
  }));

  const { data: informationGapData } = useQuery(queries.GET_L_INFORMATIONGAP);
  const informationGaps = informationGapData?.lInformationGaps ?? [];
  const informationGapOptions = informationGaps.map((s: any) => ({
    label: <span style={{ fontWeight: 550 }}>{s.information_gap}</span>,
    value: s.information_gap,
  }));

  const { data: targetIdentificationData } = useQuery(queries.GET_L_TARGETIDENTIFICATION);
  const targetIdentifications = targetIdentificationData?.lTargetIdentifications ?? [];
  const targetIdentificationOptions = targetIdentifications.map((s: any) => ({
    label: <span style={{ fontWeight: 550 }}>{s.target_identification}</span>,
    value: s.target_identification,
  }));

  //const { data: numberOfVerificationData } = useQuery(queries.GET_L_NUMBEROFVERIFICATION);
  //const numberOfVerifications = numberOfVerificationData?.lNumberOfVerifications ?? [];
  // const numberOfVerificationOptions = numberOfVerifications.map((s: any) => ({
  //   label: s.number_of_verification,
  //   value: s.number_of_verification,
  // }));

  //const { data: numberOfWorkdayData } = useQuery(queries.GET_L_NUMBEROFWORKDAY);
  //const numberOfWorkdays = numberOfWorkdayData?.lNumberOfWorkdays ?? [];
  // const numberOfWorkdayOptions = numberOfWorkdays.map((s: any) => ({
  //   label: s.number_of_workday,
  //   value: s.number_of_workday,
  // }));

  const { data: systemData } = useQuery(queries.GET_L_SYSTEM);
  const systems = systemData?.lSystems ?? [];
  const systemOptions = systems.map((s: any) => ({
    label: s.system,
    value: s.system,
  }));

  const currentYear = new Date().getFullYear();
  const startYear = Math.max(2021, currentYear - 4); // Ensure startYear is no earlier than 2021

  const fisicalYearOptions = Array.from({ length: 8 }, (_, index) => ({
    value: (startYear + index).toString() + "-04-01",
    label: (startYear + index).toString() + "-04-01"
  }));

  const formRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => formRef.current, // Pass the ref of the form
    documentTitle: 'Your Document Title',
    pageStyle: `
      @media print {
        @page {
          size: A4;
          margin: 1cm; /* Adjust the margin as per your requirement */
          @top-center {
            content: "Your Title Here";
            font-size: 16px; /* Adjust font size as needed */
            /* You can further style the title here */
          }
        }
        body {
          margin: 0; /* Reset default margin */
        }
      }
    `,
  });

  return (
    <Modal
    title={
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {initialValues ? 'TV Draft Program' : 'Add TV Draft Program'}
        <ReactToPrint
          trigger={() => <Button onClick={handlePrint} style={{ marginRight: '30px' }}>Print</Button>} // Adjusted margin-right
          content={() => formRef.current} // Pass the ref of the form
        />
      </div>
    }
      open={visible}
      onOk={handleOk}
      onCancel={onCancel}
      maskClosable={false}
      width={1000}
      okText="Save"
      okButtonProps={{ disabled: !userRoles.includes('Writer') }}
    //bodyStyle={{height: 700}}
    >
      <div ref={formRef}>
      <Form
        form={form}
        layout="vertical"
        initialValues={newInitialValues as Store}
        requiredMark={false}
      >
      <Form.Item
        name="SmeResponsible"
        label={
          <span style={{ fontWeight: 600 }}>
            <span style={{ color: 'steelblue', fontSize: 'larger' }}>1. Staff responsible</span> 
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <br />
            <span style={{ marginLeft: '20px', fontWeight: 450 }}>Provide the name of the subject matter expert that will be responsible for this program.</span>
          </span>
        }
        rules={[{ required: true, message: 'Staff responsible is required' }]}  
      >
        <TextArea rows={1} style={{ marginLeft: '10px', fontWeight: 'bold' }} />
      </Form.Item>

      <Form.Item
        name="ProgramName"
        label={
          <span style={{ fontWeight: 600 }}>
            <span style={{ color: 'steelblue', fontSize: 'larger' }}>2. Name the program</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <br />
            <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>All targeted audit and inspection programs require a name. Naming the program makes it easy to refer to and provides distinction between programs. There is currently no naming convention that must be followed.</span>
          </span>
        }
        rules={[{ required: true, message: 'Program Name is required' }]}     
      >
        <TextArea rows={1} style={{ marginLeft: '10px', fontWeight: 'bold' }} />
      </Form.Item>

      <Form.Item
        name="RegulatoryProblem"
        label={
          <span style={{ fontWeight: 600 }}>
            <span style={{ color: 'steelblue', fontSize: 'larger' }}>3. Regulatory problem (issue) to target</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <br />
            <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>A targeted verification program will clearly identify the regulatory problem it is intended to target. If the target is intended to prevent a risk from occurring, or if the risk has already occurred, this should be provided in describing the issue.  Provide the details of the regulatory problem.</span>
          </span>
        }
        rules={[{ required: true, message: 'Regulatory Problem is required' }]}       
      >
       <TextArea rows={3} style={{ marginLeft: '10px', fontWeight: 'bold' }} />
      </Form.Item>

      <Form.Item
        name="RequirementsToVerify"
        label={
          <span style={{ fontWeight: 600 }}>
            <span style={{ color: 'steelblue', fontSize: 'larger' }}>4. Requirements to verify</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <br />
            <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>A targeted verification program will clearly identify the applicable requirements or rules that must be verified. Provide the list of requirements that must be verified to address the regulatory problem. Be as specific as possible since all inspections or audits for this program will be reviewed relative to the regulatory problem.</span>
          </span>
        }
        rules={[{ required: true, message: 'REquirement is required' }]}       
      >
        <TextArea rows={3} style={{ marginLeft: '10px', fontWeight: 'bold' }} />
      </Form.Item>

      <Form.Item
        name="Reason"
        label={
          <span style={{ fontWeight: 600 }}>
            <span style={{ color: 'steelblue', fontSize: 'larger' }}>5. Reason</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <br />
            <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>Select the applicable reason for the verification program.</span>
          </span>
        }
        rules={[{ required: true, message: 'Reason is required' }]}       
      >
        <Radio.Group>
          {reasonOptions.map((option: any, index: any) => (
            <Radio key={index} value={option.value} style={{ display: 'flex', marginBottom: '10px', borderColor: 'Red' }}>
              <span style={{ paddingLeft: '12px', display: 'flex' }}>
                {option.label}
              </span>
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="Intent"
        label={
          <span style={{ fontWeight: 600 }}>
            <span style={{ color: 'steelblue', fontSize: 'larger' }}>6. Intent</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <br />
            <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>Identify the intent of the targeted audits and inspections.  Identifying the intention will help measure the success of the program and better justify more or less work targeting the issue. </span>
          </span>
        }
        rules={[{ required: true, message: 'Intent is required' }]}       
      >
        <Radio.Group>
          {intentOptions.map((option: any, index: any) => (
            <Radio key={index} value={option.value} style={{ display: 'flex', marginBottom: '10px', borderColor: 'Red' }}>
              <span style={{ paddingLeft: '12px', display: 'flex' }}>
                {option.label}
              </span>
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

  
      <Form.Item
        name="Risk"
        label={
          <span style={{ fontWeight: 600 }}>
            <span style={{ color: 'steelblue', fontSize: 'larger' }}>7. Risk</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <br />
            <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>The risk describes what would occur or be realized if the issue were left unchecked or unmanaged. The risk indicates that a change in state has or is occurring and is directly impacting the AER’s ability to uphold our mandate.</span>
          </span>
        }
        rules={[{ required: true, message: 'Risk is required' }]}       
      >
        <Radio.Group>
          {riskOptions.map((option: any, index: any) => (
            <Radio key={index} value={option.value} style={{ display: 'flex', marginBottom: '10px', borderColor: 'Red' }}>
              <span style={{ paddingLeft: '12px', display: 'flex' }}>
                {option.label}
              </span>
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="Consequence"
        label={
          <span style={{ fontWeight: 600 }}>
            <span style={{ color: 'steelblue', fontSize: 'larger' }}>8. Consequence</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <br />
            <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>The consequence refers to what may be impacted when a risk occurs. The consequences are directly related to the AER’s mandate.</span>
          </span>
        }
        rules={[{ required: true, message: 'Consequence is required' }]}       
      >
        <Radio.Group>
          {consequenceOptions.map((option: any, index: any) => (
            <Radio key={index} value={option.value} style={{ display: 'flex', marginBottom: '10px', borderColor: 'Red' }}>
              <span style={{ paddingLeft: '12px', display: 'flex' }}>
                {option.label}
              </span>
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="InformationGap"
        label={
          <span style={{ fontWeight: 600 }}>
            <span style={{ color: 'steelblue', fontSize: 'larger' }}>9. Identify the information gap</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <br />
            <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>It is essential that the targeted program identifies the information collection gap in the current approach.  Audit and inspection programs will typically use assessment criteria and random verification to provide oversight during all lifecycle stages of development. Targeted programs will fill the gaps where assessment criteria are not possible or where random verification does not collect sufficient information.  In some cases, a targeted program will be necessary because we did not receive a required event from the company so our assessment criteria could not even be applied.</span>
          </span>
        }
        rules={[{ required: true, message: 'Information gap is required' }]}       
      >
        <Radio.Group>
          {informationGapOptions.map((option: any, index: any) => (
            <Radio key={index} value={option.value} style={{ display: 'flex', marginBottom: '10px', borderColor: 'Red' }}>
              <span style={{ paddingLeft: '12px', display: 'flex' }}>
                {option.label}
              </span>
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      
      <Form.Item
      name="HazardType"
      label={
        <span style={{ fontWeight: 600 }}>
          <span style={{ color: 'steelblue', fontSize: 'larger' }}>10. Hazard</span>
          <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
          <br />
          <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>The AER has identified four hazards in which to classify all AER-regulated activities – geophysical, wells, pipelines, and facilities. If the hazard is not a geophysical activity, a well, or a pipeline, it should be identified as a facility. Identify the applicable hazard type(s).</span>
        </span>
      }
      rules={[{ required: true, message: 'Hazard is required' }]}  
    >
      <Checkbox.Group style={{ width: '100%' }}>
        <Row>
          {hazardOptions.map((option:any) => (
            <Col key={option.value} >
              <Checkbox value={option.value}>{option.label}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </Form.Item>

      <Form.Item
      name="ResourceType"
      label={
        <span style={{ fontWeight: 600 }}>
          <span style={{ color: 'steelblue', fontSize: 'larger' }}>11. Resource</span>
          <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
          <br />
          <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>Identify the applicable resource type(s).</span>
        </span>
      }
      rules={[{ required: true, message: 'Resource is required' }]}  
    >
      <Checkbox.Group style={{ width: '100%' }}>
        <Row>
          {resourceOptions.map((option:any) => (
            <Col key={option.value} span={8}>
              <Checkbox value={option.value}>{option.label}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </Form.Item>

    <Form.Item
        name="LifeCycleStage"
        label={
          <span style={{ fontWeight: 600 }}>
            <span style={{ color: 'steelblue', fontSize: 'larger' }}>12. Lifecycle stage</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <br />
            <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>Identify the applicable lifecycle stage.</span>
          </span>
        }
        rules={[{ required: true, message: 'Lifecycle stage is required' }]}       
      >
        <Radio.Group>
          {lifeCycleOptions.map((option: any, index: any) => (
            <Radio key={index} value={option.value} style={{ display: 'flex', marginBottom: '10px', borderColor: 'Red' }}>
              <span style={{ paddingLeft: '12px', display: 'flex' }}>
                {option.label}
              </span>
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="Discipline"
        label={
          <span style={{ fontWeight: 600 }}>
            <span style={{ color: 'steelblue', fontSize: 'larger' }}>13. Discipline</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <br />
            <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>Identify the discipline for the targeted program.  If another other option is required, send a request to IDAChangeRequests@aer.ca. </span>
          </span>
        }
        rules={[{ required: true, message: 'Discipline is required' }]}       
      >
          <Select
            style={{ marginLeft: '10px', fontWeight: 'bold' }} 
            options={disciplineOptions} />
        </Form.Item>

        <Form.Item
        name="TargetIdentification"
        label={
          <span style={{ fontWeight: 600 }}>
            <span style={{ color: 'steelblue', fontSize: 'larger' }}>14. Identify the event or activity or infrastructure to be targeted</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <br />
            <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>A targeted program needs to clearly identify what to audit or inspect with a list, or how to know what to audit or inspect when an event is received. If there is no current opportunity to receive/collect information at the time where a risk or uncertainty could be present, and assessment criteria cannot be applied (i.e., missing events), it needs to be clear on how to discover these events.<br></br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;● If an activity is planned to be targeted, then it should be clear how it needs to be identified.<br></br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;● If a submission is to be targeted, then it should be clear what attributes will be used to identify it for audit or inspection. <br></br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;● If the plan is to target a particular existing infrastructure, a list of sites will need to be provided.<br></br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;● If the program is looking for a missing event it should be clear how they are discovered, or which report needs to be generated.  </span>
          </span>
        }
        rules={[{ required: true, message: 'Target Identification is required' }]}       
      >
      <Radio.Group>
          {targetIdentificationOptions.map((option: any, index: any) => (
            <Radio key={index} value={option.value} style={{ display: 'flex', marginBottom: '10px', borderColor: 'Red' }}>
              <span style={{ paddingLeft: '12px', display: 'flex' }}>
                {option.label}
              </span>
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="TargetDetail"
        label={
          <span style={{ fontWeight: 600 }}>
            <span style={{color: 'steelblue', fontSize: 'larger' }}>15. If you selected #1 from question 14 then answer:</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <span style={{ marginLeft: '40px', fontWeight: 450, display: 'inline-block' }}>Provide the specific details that will be required in the assessment criteria to identify the specific event. E.g., All drilling spud notifications within 10km of Slave Lake must be identified for inspection, all site entries from company XYZ, all pipeline construction notifications that have a substance of lithium. </span>
            <br />
            <span style={{marginLeft: '20px', color: 'steelblue', fontSize: 'larger' }}>If you selected #2 from question 14 then answer:</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <span style={{ marginLeft: '40px', fontWeight: 450, display: 'inline-block' }}>Identify how the random selection will be conducted.  E.g., On January 1st 50 of the 700 gas plants will be randomly selected for the program, every 10th abandonment notification will be selected until the objective is achieved.</span>
            <br />
            <span style={{marginLeft: '20px', color: 'steelblue', fontSize: 'larger' }}>If you selected #3 from question 14 then answer:</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <span style={{ marginLeft: '40px', fontWeight: 450, display: 'inline-block' }}>Describe the list and how it was generated. E.g., A list of 20 remote drilling sumps has been provided and it was selected by the SME, a list of 50 facilities with suspected storage tanks was provided by the SME.</span>
            <br />
            <span style={{marginLeft: '20px', color: 'steelblue', fontSize: 'larger' }}>If you selected #4 from question 14 then answer:</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <span style={{ marginLeft: '40px', fontWeight: 450, display: 'inline-block' }}>Describe what the report is called and how it will work.  E.g., The flaring report will be run on the 1st of every month and staff are expected to assign and verify the top 5 sites from the list, at the beginning of every quarter the drilling notifications received will be compared with the list of new wells in the database and the ones that don't have a drilling notification will be identified.</span>
            <br />
          </span>
        }
        rules={[{ required: true, message: 'Target detail is required' }]}       
      >
       <TextArea rows={3} style={{ marginLeft: '10px', fontWeight: 'bold' }} />
      </Form.Item>

      <Form.Item
        name= "PreferredStartDate"
        label={
          <span style={{ fontWeight: 600 }}>
            <span style={{ color: 'steelblue', fontSize: 'larger' }}>16. Identify the preferred start date</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <br />
            <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>When selecting a start date consider the approval process and implementation and planning. Also, targeted programs can be started at almost any time and do not have to align with annual planning practices.</span>
          </span>
        }
        rules={[{ required: true, message: 'Preferred StartDate is required' }]}       
        >
          <DatePicker />
      </Form.Item>

      <Form.Item
        name= "PreferredEndDate"
        label={
          <span style={{ fontWeight: 600 }}>
            <span style={{ color: 'steelblue', fontSize: 'larger' }}>17. Identify the preferred end/review date</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <br />
            <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>When the program has elapsed, a review is required for all targeted programs. (All programs should end or be reviewed no later than March 31st of the following year.)</span>
          </span>
        }
        rules={[{ required: true, message: 'Preferred EndDate is required' }]}       
        >
          <DatePicker />
      </Form.Item>

      <Form.Item
      name="Staff"
      label={
        <span style={{ fontWeight: 600 }}>
          <span style={{ color: 'steelblue', fontSize: 'larger' }}>18. Staff</span>
          <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
          <br />
          <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>Identify the staff resources required to conduct the audits or inspections.</span>
        </span>
      }
      rules={[{ required: true, message: 'Staff is required' }]}  
    >
      <Checkbox.Group style={{ width: '100%' }}>
        <Row>
          {staffOptions.map((option:any) => (
            <Col key={option.value} span={18}>
              <Checkbox value={option.value}>{option.label}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </Form.Item>

    <Form.Item
        name="NumberOfVerification"
        label={
          <span style={{ fontWeight: 600 }}>
            <span style={{ color: 'steelblue', fontSize: 'larger' }}>19. Number</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <br />
            <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>Identify the number of audits or inspections that need to be conducted. </span>
          </span>
        }
        rules={[{ required: true, message: 'Number is required' }]}       
      >
           <InputNumber style={{ width: 200 }} />
        </Form.Item>

        <Form.Item
        name="NumberOfWorkday"
        label={
          <span style={{ fontWeight: 600 }}>
            <span style={{ color: 'steelblue', fontSize: 'larger' }}>20. Time/workdays</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <br />
            <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>Identify how much time/workdays will be required to complete this program. </span>
          </span>
        }
        rules={[{ required: true, message: 'Time/workday is required' }]}       
      >
           <InputNumber style={{ width: 200 }} />
        </Form.Item>

        <Form.Item
        name="System"
        label={
          <span style={{ fontWeight: 600 }}>
            <span style={{ color: 'steelblue', fontSize: 'larger' }}>21. System</span>
            <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
            <br />
            <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>There are various ways to record the results when a targeted audit or inspection is being done. The team conducting the work will need to figure out the most effective option.  Some teams can record this information in FIS or CMS, another system, but others may need to create a spreadsheet on a shared drive.</span>
          </span>
        }
        rules={[{ required: true, message: 'System is required' }]}       
      >
        <Radio.Group>
          {systemOptions.map((option: any, index: any) => (
            <Radio key={index} value={option.value} style={{ display: 'flex', marginBottom: '10px', borderColor: 'Red' }}>
              <span style={{ paddingLeft: '12px', display: 'flex' }}>
                {option.label}
              </span>
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
          name="FiscalYearStartDate"label={
            <span style={{ fontWeight: 600 }}>
              <span style={{ color: 'steelblue', fontSize: 'larger' }}>22. Fiscal year start date</span>
              <span style={{ color: 'Red', fontSize: 'larger' }}>&nbsp;*</span> {/* Validation indicator */}
              <br />
              <span style={{ marginLeft: '20px', fontWeight: 450, display: 'inline-block' }}>Select the Fiscal year start date of this program.</span>
            </span>
          }
          rules={[{ required: true, message: 'Fiscal year is required' }]}>
          <Select
            style={{ width: 140 }}
            options={fisicalYearOptions} />
        </Form.Item>

      <Divider style={{ borderWidth: '2px' }} />

        <Form.Item name="Id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="Creator" label="Created by:" style={{ display: 'inline-block', marginLeft: '30px' }}>
          <Input style={{ backgroundColor: '#f0f0f0' }} readOnly/>
        </Form.Item>
        <Form.Item name="CreationInstant" label="Creation date:" style={{ display: 'inline-block', marginLeft: '30px' }}>
          <Input style={{ backgroundColor: '#f0f0f0' }} readOnly/>
        </Form.Item>
        <Form.Item name="Modifier" label="Modified by:" style={{ display: 'inline-block', marginLeft: '30px' }}>
          <Input style={{ backgroundColor: '#f0f0f0' }} readOnly/>
        </Form.Item>
        <Form.Item name="ModificationInstant" label="Modification date:" style={{ display: 'inline-block', marginLeft: '30px' }}>
          <Input style={{ backgroundColor: '#f0f0f0' }} readOnly/>
        </Form.Item>
      </Form>
      </div>
    </Modal>
  );
};

export default TvpDraftProgramFormModal;

