import { useState } from 'react';
import { Typography, Button, notification, Modal, Space, Input } from 'antd';
import { useQuery } from '@apollo/client';

import { TVP_Draft_Program, TVP_Final_Program} from '../../graphql/types';
import { GET_TVP_DRAFT_PROGRAMS} from '../../graphql/queries';
import { GET_TVP_FINAL_PROGRAMS} from '../../graphql/queries';

import config from '../../config';
import {
  useCreateTvpDraftProgramMutation,
  useUpdateTvpDraftProgramMutation,
  useDeleteTvpDraftProgramMutation,
  usePublishTvpDraftProgramMutation,
} from '../../hooks/useMutations';

import TvpDraftProgramTable from '../../components/TvpDraftProgramTable';

import TvpDraftProgramFormModal from '../../components/TvpDraftProgramFormModal';

function TvpDraft() {
  // @ts-ignore
  const userRoles: string[] = JSON.parse( localStorage.getItem(config.rolesKey));
   // @ts-ignore
   const idTokenClaims = JSON.parse(localStorage.getItem(config.tokenClaimsKey));
   const userEmail = idTokenClaims.preferred_username;

  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [deletingTvpDraftProgram, setDeletingTvpDraftProgram] = useState<TVP_Draft_Program | undefined>(undefined);
  const [publishConfirmationVisible, setPublishConfirmationVisible] = useState(false);
  const [publishingTvpDraftProgram, setPublishingTvpDraftProgram] = useState<TVP_Draft_Program | undefined>(undefined);
  const [inputApproverValue, setInputApproverValue] = useState('');
  const [inputApprovalDateValue, setInputApprovalDateValue] = useState('');

  const [visibleProgramForm, setVisibleProgramForm] = useState(false);
  const [editingTvpDraftProgram, setEditingTvpDraftProgram] = useState<TVP_Draft_Program | undefined>(undefined);


  const { loading, error, data } = useQuery(GET_TVP_DRAFT_PROGRAMS);

  const [createTvpDraftProgram] = useCreateTvpDraftProgramMutation();
  const [updateTvpDraftProgram] = useUpdateTvpDraftProgramMutation();
  const [deleteTvpDraftProgram] = useDeleteTvpDraftProgramMutation();
  const [publishTvpDraftProgram] = usePublishTvpDraftProgramMutation();


  const showModal = (editingTvpDraftProgram?: TVP_Draft_Program) => {
    setEditingTvpDraftProgram(editingTvpDraftProgram);
    setVisibleProgramForm(true);
  };


  const handleCreate = async (values: TVP_Draft_Program) => {
    try {
      const { data } = await createTvpDraftProgram({ variables: { newTvpDraftProgram: values } });
      if (data?.createTvpDraftProgram && data?.createTvpDraftProgram.Id) {
        setVisibleProgramForm(false);
        notification.success({ message: 'Tvp draft program added successfuly!' });
      }
      else {
        notification.error({ message: 'Adding tvp draft program failed!' });
      }
    } catch (error) {
      notification.error({ message: 'Adding tvp draft program failed!' });
    }
  };

  const handleUpdate = async (values: TVP_Draft_Program) => {
    try {
      const { data } = await updateTvpDraftProgram({
        variables: { newTvpDraftProgram: values },
      });
      if (data?.updateTvpDraftProgram) {
        setVisibleProgramForm(false);
        notification.success({ message: 'Update successful!' });
      }
      else {
        notification.error({ message: 'Update failed!' });
      }
    } catch (error) {
      notification.error({ message: 'Update failed!' });
    }
  };

  const handleUpdateOrCreate = async (values: TVP_Draft_Program, createMode: boolean) => {
    if (createMode) handleCreate(values);
    else handleUpdate(values);
  };


  const handleDelete = async (currentTvpDraftProgram: TVP_Draft_Program) => {
    setDeletingTvpDraftProgram(currentTvpDraftProgram);
    setDeleteConfirmationVisible(true);
  };

  const { loading: finalLoading, error: finalError, data: finalData, refetch: refetchTVPFinal } = useQuery(GET_TVP_FINAL_PROGRAMS);
  const tvpFinalPrograms = finalData?.tvpFinalPrograms ?? [];
  refetchTVPFinal();
  const handlePublish = async (currentTvpDraftProgram: TVP_Draft_Program) => {
    setPublishingTvpDraftProgram(currentTvpDraftProgram);

    // Check if the record to be published already exists in tvpFinalPrograms
    const existingRecord = tvpFinalPrograms.find((program: TVP_Final_Program) =>
      program.ProgramName === currentTvpDraftProgram.ProgramName &&
      program.FiscalYearStartDate === currentTvpDraftProgram.FiscalYearStartDate
    );

    if (existingRecord) {
      // Display a warning modal
      Modal.confirm({
        title: 'Duplicate Record',
        content: 'A record with the same "Program Name" and "FY Start Date" already exists in TVP Final. Do you want to continue publishing?',
        okText: 'Publish',
        cancelText: 'Cancel',
        onOk: async () => {
          // Proceed with publishing
          setPublishConfirmationVisible(true);
        },
        onCancel: () => {
          // Handle cancel action here
          // For example, close the modal
          setPublishConfirmationVisible(false);
        }
      });
    } else {
      // No duplicates found, proceed with publishing directly
      setPublishConfirmationVisible(true);
    }
  };

  const confirmDelete = async () => {
    try {
      const { data } = await deleteTvpDraftProgram({
        variables: { Id: deletingTvpDraftProgram?.Id || "" },
      });
      if (data?.deleteTvpDraftProgram) {
        setVisibleProgramForm(false);
        notification.success({ message: "Deletion successful!" });
      }
      else {
        notification.error({ message: "The record was not deleted!" });
      }

    } catch (error) {
      notification.error({ message: "The record was not deleted!" });
    }
    setDeleteConfirmationVisible(false);
  };

  
  const confirmPublish = async () => {
    try {
      if (inputApproverValue && inputApprovalDateValue) {
        const { data } = await publishTvpDraftProgram({
          variables: {
            Id: publishingTvpDraftProgram?.Id || "",
            ApprovedBy: inputApproverValue, ApprovalDate: inputApprovalDateValue,
            Creator: userEmail
          },
        });
        if (data?.publishTvpDraftProgram) {
          notification.success({ message: "Publishing successful!" });
          refetchTVPFinal();
        }
        else {
          notification.error({ message: "The program was not published!" });
        }
      }
      else {
        notification.error({ message: "The required information must be filled!" });
      }
    } catch (error) {
      notification.error({ message: "The program was not published!" });
    }
    setPublishConfirmationVisible(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const tvpDraftPrograms = data?.tvpDraftPrograms ?? [];

  // Calculate today's date
  const today = new Date().toISOString().split('T')[0];

  return (
    <div>
      <Typography.Title level={3}>
        List of Targeted Verification Programs (Draft)
      </Typography.Title>
      <div className="site-layout-content">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Button type="primary" onClick={() => showModal()} style={{ marginBottom: '6px' }}
          disabled={!userRoles.includes('Writer')}>
            Create a New Program
          </Button>
        </div>

        <TvpDraftProgramTable
          tvpDraftPrograms={tvpDraftPrograms}
          onEdit={showModal}
          onDelete={handleDelete}
          onPublish={handlePublish}
        />
        {visibleProgramForm && (
          <TvpDraftProgramFormModal
            visible={visibleProgramForm}
            initialValues={editingTvpDraftProgram as TVP_Draft_Program}
            onSubmit={handleUpdateOrCreate}
            onCancel={() => setVisibleProgramForm(false)}
          />
        )}
       
        <Modal
          open={deleteConfirmationVisible}
          title="Confirm Deletion"
          okText="Delete"
          cancelText="Cancel"
          onCancel={() => setDeleteConfirmationVisible(false)}
          onOk={confirmDelete}
          okButtonProps={{ danger: true }}
          maskClosable={false}
        >
          Are you sure you want to delete this program?
        </Modal>
        
        <Modal
          open={publishConfirmationVisible}
          title="To publish, please enter the following information."
          okText="Publish"
          cancelText="Cancel"
          onCancel={() => setPublishConfirmationVisible(false)}
          onOk={confirmPublish}
          okButtonProps={{ danger: true }}
          maskClosable={false}
        >
          <div style={{ marginBottom: '6px', paddingLeft: '16px' }}>
           
          </div>
          <div style={{ marginBottom: '6px', paddingLeft: '16px' }}>
            <Space>
              <Typography.Text strong>Approved By:</Typography.Text>
              {' '}
              <Input value={inputApproverValue} style={{width: 200 }} onChange={(e) => setInputApproverValue(e.target.value)} />
            </Space>
          </div>
          <div style={{ paddingLeft: '16px' }}>
            <Space>
              <Typography.Text strong>Approval Date:</Typography.Text>
              {'             '}
              <Input
                type="date"
                value={inputApprovalDateValue}
                style={{width: 200 }}
                onChange={(e) => setInputApprovalDateValue(e.target.value)}
                max={today}
              />
            </Space>
          </div>
        </Modal>
      </div>
    </div>
  );

}
export default TvpDraft