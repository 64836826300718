import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../../pages/dashboard";
import RvpDraft from "../../pages/rvpDraft";
import RvpFinal from "../../pages/rvpFinal";
import TargetDraft from "../../pages/targetDraft";
import TargetFinal from "../../pages/targetFinal";
import WellPopulation from "../../pages/sampleRVP/wellPopulation";
import DrillPopulation from "../../pages/sampleRVP/drillPopulation";
import { ApolloProvider } from "@apollo/client";
import createApolloClient from "../../apollo-client";
import { useMsal } from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";

function AppRoutes(props: { graphQLEndpoint: string }) {
  const [accessToken, setAccessToken] = useState("");
  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    if (!accessToken && inProgress === InteractionStatus.None) {
      const tokenRequest = {
        account: accounts[0],
        scopes: ["User.Read"],
      };

      // Acquire an access token
      instance
        .acquireTokenSilent(tokenRequest)
        .then((response) => {
          setAccessToken(response.accessToken);
        })
        .catch(async (e) => {
          // Catch interaction_required errors and call interactive method to resolve
          if (e instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect(tokenRequest);
          }

          throw e;
        });
    }
  }, [instance, accounts, inProgress, accessToken]);

  let apolloClient = createApolloClient(props.graphQLEndpoint, accessToken);

  return (
    <ApolloProvider client={apolloClient}>
      <Routes>
        <Route path="/" element={<Dashboard />}></Route>;
        <Route path="/rvpDraft" element={<RvpDraft />}></Route>;
        <Route path="/rvpFinal" element={<RvpFinal />}></Route>;
        <Route path="/targetDraft" element={<TargetDraft />}></Route>;
        <Route path="/targetFinal" element={<TargetFinal />}></Route>;
        <Route path="/sampleRVP/wellPopulation" element={<WellPopulation />} />
        <Route path="/sampleRVP/drillPopulation" element={<DrillPopulation />} />
      </Routes>
    </ApolloProvider>
  );
}

export default AppRoutes;
