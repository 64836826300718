import { gql } from '@apollo/client';

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    user_name
    name
    age
    title
  }
`;


export const RvpDraftProgramFragment = gql`
  fragment RvpDraftProgramFragment on RVP_Draft_Program {
    idSk
    program
    hazard_type
    resource_type
    life_cycle_stage
    event_category
    discipline
    population
    sample_size_total
    average_confidence_level
    variables_in_strata
    extraction_details
    staff
    fiscal_year_start_date
    comment
    row_created_by
    row_create_date_time
    row_modified_by
    row_modify_date_time
  }
`;

export const RvpDraftStratumFragment = gql`
  fragment RvpDraftStratumFragment on RVP_Draft_Stratum {
    idSk
    rvp_draft_id_sk
    stratum_id
    location
    operator
    activity
    stratum_population
    confidence_level
    sample_proportion
    margin_of_error
    extraction_method
    extraction_frequency
    comment
    row_created_by
    row_create_date_time
    row_modified_by
    row_modify_date_time
    z_value
    sample_size_x
    sample_size
    percent_of_population
    samples_per_extraction
    sample_extraction_rate
    statistical_statement
    annotation
  }
`;

export const RProgramFragment = gql`
  fragment RProgramFragment on R_Program {
    program
    active
  }
`;

export const RvpFinalProgramFragment = gql`
  fragment RvpFinalProgramFragment on RVP_Final_Program {
    idSk
    program
    hazard_type
    resource_type
    life_cycle_stage
    event_category
    discipline
    population
    sample_size_total
    average_confidence_level
    variables_in_strata
    extraction_details
    staff
    fiscal_year_start_date
    comment
    sme_responsible
    approved_by
	  approval_date
    row_created_by
    row_create_date_time
    row_modified_by
    row_modify_date_time
    active
  }
`;

export const RvpFinalStratumFragment = gql`
  fragment RvpFinalStratumFragment on RVP_Final_Stratum {
    idSk
    rvp_final_id_sk
    stratum_id
    location
    operator
    activity
    stratum_population
    confidence_level
    sample_proportion
    margin_of_error
    extraction_method
    extraction_frequency
    comment
    row_created_by
    row_create_date_time
    row_modified_by
    row_modify_date_time
    z_value
    sample_size_x
    sample_size
    percent_of_population
    samples_per_extraction
    sample_extraction_rate
    statistical_statement
    active
    annotation
  }
`;


export const RvpFinalProgramStrataFragment = gql`
  fragment RvpFinalProgramStrataFragment on RVP_Final_Program_Strata {
    idSk
    program
    hazard_type
    resource_type
    life_cycle_stage
    event_category
    discipline
    population
    sample_size_total
    average_confidence_level
    variables_in_strata
    extraction_details
    staff
    fiscal_year_start_date
    comment
    sme_responsible
    approved_by
    approval_date
    row_created_by
    row_create_date_time
    row_modified_by
    row_modify_date_time
    active
    strata {
      idSk
      rvp_final_id_sk
      stratum_id
      location
      operator
      activity
      stratum_population
      confidence_level
      sample_proportion
      margin_of_error
      extraction_method
      extraction_frequency
      comment
      row_created_by
      row_create_date_time
      row_modified_by
      row_modify_date_time
      z_value
      sample_size_x
      sample_size
      percent_of_population
      samples_per_extraction
      sample_extraction_rate
      statistical_statement
      active
      annotation
    }
  }
`;

export const RvpDraftProgramStrataFragment = gql`
  fragment RvpDraftProgramStrataFragment on RVP_Draft_Program_Strata {
    idSk
    program
    hazard_type
    resource_type
    life_cycle_stage
    event_category
    discipline
    population
    sample_size_total
    average_confidence_level
    variables_in_strata
    extraction_details
    staff
    fiscal_year_start_date
    comment
    row_created_by
    row_create_date_time
    row_modified_by
    row_modify_date_time
    strata {
      idSk
      rvp_draft_id_sk
      stratum_id
      location
      operator
      activity
      stratum_population
      confidence_level
      sample_proportion
      margin_of_error
      extraction_method
      extraction_frequency
      comment
      row_created_by
      row_create_date_time
      row_modified_by
      row_modify_date_time
      z_value
      sample_size_x
      sample_size
      percent_of_population
      samples_per_extraction
      sample_extraction_rate
      statistical_statement
      annotation
    }
  }
`;

export const TvpDraftProgramFragment = gql`
  fragment TvpDraftProgramFragment on TVP_Draft_Program {
    Id
    ProgramName
    RegulatoryProblem
    RequirementsToVerify
    Reason
    Intent
    Risk
    Consequence
    InformationGap
    HazardType
    ResourceType
    LifeCycleStage
    Discipline
    TargetIdentification
    TargetDetail
    SmeResponsible
    PreferredStartDate
    PreferredEndDate
    Staff
    NumberOfVerification
    NumberOfWorkday
    System
    FiscalYearStartDate
    Creator
    CreationInstant
    Annotation
    Modifier
    ModificationInstant
  }
`;

export const TvpFinalProgramFragment = gql`
  fragment TvpFinalProgramFragment on TVP_Final_Program {
    Id
    ProgramName
    RegulatoryProblem
    RequirementsToVerify
    Reason
    Intent
    Risk
    Consequence
    InformationGap
    HazardType
    ResourceType
    LifeCycleStage
    Discipline
    TargetIdentification
    TargetDetail
    SmeResponsible
    ApprovedBy
    ApprovalDate
    PreferredStartDate
    PreferredEndDate
    Staff
    NumberOfVerification
    NumberOfWorkday
    FiscalYearStartDate
    System
    Creator
    CreationInstant
    Annotation
    Modifier
    ModificationInstant
    IsActive
  }
`;