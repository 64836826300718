import React, { useState, useEffect } from 'react';
import { Table, Button, DatePicker, message, Typography } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_INSPECTION, GET_WELL_POPULATION, UPDATE_RVP_DRAFT_STRATUM_POPULATION } from '../../graphql/queries';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';

type SamplePopulation = {
  StratumId: string;
  Description: string;
  StratumPopulation: number;
};

type Inspection = {
  InspectionId: string;
};

const DrillPopulation: React.FC = () => {
  const [samplePopulations, setSamplePopulations] = useState<SamplePopulation[]>([]);
  const [inspectionData, setInspectionData] = useState<Inspection[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [cutOffDate, setCutOffDate] = useState<string | null>(dayjs().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'));

  const [fetchData, { data, loading: queryLoading, error: queryError }] = useLazyQuery(GET_WELL_POPULATION, {
    fetchPolicy: 'network-only',
  });

  const [fetchInspectionData, { data: inspectionQueryData, loading: inspectionQueryLoading, error: inspectionQueryError }] = useLazyQuery(GET_INSPECTION, {
    fetchPolicy: 'network-only',
  });

  const [updateRvpDraftStratum] = useMutation(UPDATE_RVP_DRAFT_STRATUM_POPULATION);

  useEffect(() => {
    if (data && data.wellPopulation) {
      setSamplePopulations(data.wellPopulation);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (queryError) {
      setError(queryError.message);
      setLoading(false);
    }
  }, [queryError]);

  useEffect(() => {
    fetchInspectionData();
  }, [fetchInspectionData]);

  useEffect(() => {
    if (inspectionQueryData && inspectionQueryData.inspection) {
      setInspectionData(inspectionQueryData.inspection);
    }
  }, [inspectionQueryData]);

  useEffect(() => {
    if (inspectionQueryError) {
      message.error("Error fetching inspection data: " + inspectionQueryError.message);
    }
  }, [inspectionQueryError]);

  const handleFetchData = () => {
    if (cutOffDate && !loading) {
      setError(null);
      setSamplePopulations([]);
      setLoading(true);
      fetchData({ variables: { cutOffDate } })
        .then(() => setLoading(false))
        .catch(err => {
          setError(err.message);
          setLoading(false);
        });
    } else if (!cutOffDate) {
      setError("Please select a cutoff date.");
    }
  };

  const handleDateChange = (date: any, dateString: string) => {
    setCutOffDate(dateString);
    setError(null);
    setSamplePopulations([]);
  };

  const handleUpdatePopulation = () => {
    if (!samplePopulations.length) {
      message.error("No data available to update.");
      return;
    }

    setLoading(true);

    const updatePromises = samplePopulations.map(population =>
      updateRvpDraftStratum({
        variables: {
          stratumId: population.StratumId,
          population: population.StratumPopulation
        },
      })
    );

    Promise.all(updatePromises)
      .then(results => {
        const failedUpdates = results
          .map((result, index) => (!result.data.updateRvpDraftStratumPopulation ? samplePopulations[index].StratumId : null))
          .filter(stratumId => stratumId !== null);
        
        if (failedUpdates.length === 0) {
          message.success("Population updated successfully.");
        } else {
          message.error(`Some updates failed for Stratum IDs: ${failedUpdates.join(', ')}`);
        }
      })
      .catch(err => {
        message.error("Error updating population: " + err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<SamplePopulation> = [
    {
      title: 'Stratum ID',
      dataIndex: 'StratumId',
      key: 'StratumId',
      width: 150,
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description',
      width: 200,
    },
    {
      title: 'Population',
      dataIndex: 'StratumPopulation',
      key: 'StratumPopulation',
      align: 'right',
      width: 150,
    },
  ];

  const inspectionColumns: ColumnsType<Inspection> = [
    {
      title: 'Inspection ID',
      dataIndex: 'PriorityListId',
      key: 'PriorityListId',
      width: 150,
    },
  ];

  return (
    <div>
      
          <Typography.Title level={4} style={{ marginTop: '24px' }}>Test Page for LightSaber2</Typography.Title>
          <Table
            columns={inspectionColumns}
            dataSource={inspectionData}
            rowKey="PriorityListId"
            pagination={{ pageSize: 10 }} 
            style={{ width: '400px', marginLeft: 0 }}
          />
        
    
    </div>
  );
};

export default DrillPopulation;
