import {Button} from 'antd';
import { useMsal } from '@azure/msal-react';
import config from '../config';

export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleSignOut = () => {
        localStorage.removeItem(config.rolesKey);
        localStorage.removeItem(config.tokenClaimsKey);
        localStorage.removeItem(config.tokenKey)
        instance.logoutRedirect();
    }

    return (
        <Button color="inherit" onClick={handleSignOut}>Sign out</Button>
    )
};